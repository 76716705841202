import { Helmet } from "react-helmet";
import lima from "images/lima.jpg";

const BlogSingleExtraInformation = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          {/* <Badge href="##" color="purple" name="Traveler" /> */}
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Datos Útiles de Lima y Cusco
          </h1>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <br></br>
        <h2>Lima, Perú</h2>
        <figure>
          <img src={lima} alt="nc blog" className="rounded-2xl" />
        </figure>
        <h3>Clima</h3>
        <p>
          La ciudad de Lima tiene un clima árido y semicálido. La temperatura
          media anual máxima es 21,97°C y la mínima 17 °C. El clima en Lima es
          muy húmedo (con una humedad promedio de 95%). La temperatura en la
          costa y en Lima fluctúa entre 28° C en el verano (diciembre a mayo) y
          13° C en el invierno (junio a noviembre).
        </p>
        <h3>Zona Horaria</h3>
        <p>La zona horaria de Lima es UTC/GMT -5 h.</p>
        <h3>Moneda Nacional</h3>
        <p>
          La moneda oficial del Perú es el Nuevo Sol (S/). Existen billetes con
          las siguientes denominaciones: S/10, S/ 20, S/. 50, S/. 100 y S/.200,
          y monedas de: S/ 5, S/ 2, S/ 1, S/ 0.50, S/ 0.20 y S/ 0.10.
        </p>
        <h3>Cambio de dinero</h3>
        <p>
          Es posible cambiar la moneda extranjera en bancos y casas de cambio.
          También existen “cambistas” callejeros, pero no se garantiza la
          transacción. El horario de atención usual de los establecimientos de
          cambio de moneda es de lunes a viernes de 9:00 a. m. a 6:00 p. m. y
          los sábados hasta el mediodía. El tipo de cambio respecto al dólar
          norteamericano es variable, consulte antes de cambiar su dinero. Para
          conocer el tipo de cambio puede visitar: www.sbs.gob.pe. El dólar
          norteamericano es aceptado en algunos locales comerciales.
        </p>
        <h3>Tarjetas de crédito y bancos</h3>
        <p>
          Los establecimientos aceptan la mayoría de tarjetas de crédito como:
          Visa, Mastercard, American Express y Diners Club. Horario de atención
          de los bancos es, por lo general, de lunes a viernes de 09:00 a 18:00
          horas y los sábados hasta medio día. Existen en las calles de las
          ciudades cajeros automáticos de los diferentes bancos.
        </p>
        <h3>Propinas</h3>
        <p>
          El monto de las propinas es variable y dependerá de su satisfacción
          respecto al servicio que le brinden (Se acostumbra dejar un 5% o 10%
          adicional al mozo). Porteros en hoteles esperan en promedio 1,00 USD
          por maleta. No hay necesidad de dar propina a los taxistas. Guías
          turísticos y otros similares esperan en promedio S/ 10 a S/ 15.
        </p>
        <h3>Electricidad</h3>
        <p>
          Ten en cuenta que la electricidad en Perú funciona con una corriente
          de 220 voltios y una frecuencia de 60 hercios (ciclos por segundo). Si
          necesitas conectar un equipo electrónico de 110 voltios, deberás
          adquirir un adaptador de corriente.
        </p>
        <h3>Número de Emergencia importantes</h3>
        <p>
          Estos son algunos números telefónicos que deberías tener agendados
          ante cualquier emergencia durante tu visita: Policía Nacional del
          Perú: 105, Cuerpo General de Bomberos Voluntarios del Perú: 116, Cruz
          Roja: 115, Defensa Civil: 110.
        </p>
        <h3>Teléfono</h3>
        <p>
          Para realizar llamadas internacionales marque: 00 + código del país +
          código de la ciudad + número telefónico.
        </p>
        <h3>Taxis</h3>
        <p>
          Los taxis en el Perú no cuentan con taxímetro y no existe un sistema
          único que permita identificarlos por una misma característica. Si
          contratas uno en plena calle, antes de tomar el servicio, debe
          preguntar por el importe y negociar. No se requiere dar propina a los
          taxistas. Es recomendable utilizar los servicios de empresas de taxi
          (se piden por teléfono o por aplicación, tales como Uber, Taxi
          Satelital, Cabify, Didi, entre otros). Los taxis autorizados por las
          municipalidades están pintados de amarillo (en Lima) y tienen un
          casquete luminoso en el techo. Se recomienda preguntar en su hotel
          para llamar un taxi seguro por teléfono.
        </p>
        <h3>Transporte urbano</h3>
        <p>
          Los servicios formales de transporte son el Metropolitano (sistema de
          buses de transporte rápido) y la Línea 1 del Metro de Lima (tren
          eléctrico). Puedes conocer más de estos servicios en
          www.metropolitano.com.pe y www.lineauno.pe.
        </p>
        <h3>Buses y “combis”</h3>
        <p>
          El medio de transporte público más utilizado en la ciudad son los
          buses y “combis” que pertenecen a empresas que cubren diferentes
          rutas. El precio puede variar entre S/ 1.20 y S/ 2.50 dependiendo de
          la distancia a recorrer.
        </p>
        <h3>Salud</h3>
        <p>
          Se recomienda beber agua embotellada o previamente hervida, ser
          cuidadoso con las verduras crudas y frutas, evitar comer en puestos de
          comida de la calle para evitar intoxicaciones.
        </p>
        <h3>Gastronomía</h3>
        <p>
          La cocina peruana es considerada como una de las mejores de América
          Latina y tiene una oferta culinaria deliciosa y muy diversa. En la
          ciudad de Lima puede encontrar restaurantes especializados en pescados
          y el famoso “ceviche”, cocinadas a la parrilla o chifa – exótica
          fusión de sazón peruana con la comida china, así como comida criolla
          como el Ají de Gallina, Papa a la Huancaína, Lomo Saltado, entre
          otros. Como bebida puede pedir la Chincha Morada (refresco a base de
          maíz morado) y para brindar un Pisco Sour o Chilcano.
        </p>
        <h3>Compras</h3>
        <p>
          Las tiendas de la ciudad de Lima están abiertas, en su mayoría, en el
          horario de 10:00h a 20:00h. Muchos comercios y mercados atienden los
          días domingos.
        </p>
        <h3>Seguridad personal</h3>
        <p>
          Se recomienda tomar precauciones de seguridad en lugares públicos y
          atracciones turísticas, no portar joyería ni dinero en exceso. Evitar
          calles poco iluminadas durante caminatas nocturnas. Asimismo, se
          recomienda contar con fotocopias de los documentos que usted considere
          importantes, tales como pasaporte, cédula de identidad entre otros.
          Guardar todo objeto de valor en las cajas de seguridad de su hotel.
        </p>
        <br></br>
        <h2>Cusco, Perú</h2>
        <figure>
          <img
            src="https://images.adsttc.com/media/images/6333/5368/4dba/6e0a/9e50/4de0/large_jpg/xix-bienal-de-arquitectura-peruana-en-cusco-presenta-su-programacion-bajo-el-tema-construyendo-el-patrimonio-del-futuro_4.jpg?1664308115"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>
        <h3>Clima</h3>
        <p>
          El clima en Cusco es templado. Las temperaturas varían de los 0ºC. a
          los 21ºC. Durante el año Cusco presenta dos estaciones diferentes: la
          estación seca (de abril a octubre) con días cálidos y temperatura
          media de 17ºC y noches más frías, especialmente en los meses de junio
          y julio donde las temperaturas pueden descender por debajo de los 0ºC.
          Por su parte, en la estación lluviosa (de noviembre a marzo) la
          temperatura durante el día promedia los 18ºC, mientras que en las
          noches, las temperaturas son significativamente más altas con una
          temperatura media de 8ºC. Sin importar la época del año, siempre se
          debe estar preparado para el sol y la lluvia. Las lluvias se pueden
          dar aisladas en cualquier momento del año.
        </p>
        <h3>Transporte</h3>
        <p>
          Los taxis de por sí es el medio de transporte más popular en Cusco. A
          la llegada al aeropuerto de Cusco te encontrarás con muchos choferes
          de taxis ofreciéndote sus servicios. Ya que los taxis no tienen cargos
          fijos por tramo, debe acordar un precio antes de abordar el taxi.
        </p>
        <h3>Aeropuerto</h3>
        <p>
          El aeropuerto de Cusco (Aeropuerto Internacional Alejandro Velasco
          Astete) está situado a 5.5 km de la Plaza de Armas en el histórico
          centro de Cusco. El viaje en taxi es de aproximadamente 20 minutos.
        </p>
        <h3>Vestimenta y objetos a llevar a Cusco</h3>
        <p>
          No olvide llegar en su viaje a Cusco un poncho para lluvias, sombrero,
          bloqueador solar, repelente contra mosquitos, casaca cortavientos,
          zapatillas cómodas, ropa abrigadora para la noche, agua, documentos de
          identidad.
        </p>
        <h3>Salud</h3>
        <p>
          El mal de altura (soroche) es causado por los 3,400 metros sobre el
          nivel del mar del Cusco. Se recomienda que descanse el primer día de
          su llegada para una buena aclimatación, beba bastante agua y evite las
          comidas grasosas. En uno o dos días se sentirá mejor. Si tiene
          problemas cardiacos es mejor que consulte su médico antes de viajar a
          Cusco. No tomar nunca agua del grifo, consumir solo agua en botella,
          verificando que este bien sellada, o hervida
        </p>
        <h3>Propinas</h3>
        <p>
          Se acostumbra dejar 10% de propina para el mesero en los restaurantes.
          En el caso de Guías del turismo y chóferes la propina es voluntaria,
          casi nunca se deja propina a los taxistas ya que el precio se pacta de
          antemano.
        </p>
        <h3>Bancos y casa de cambio</h3>
        <p>
          La mayoría de los bancos y casas de cambio se concentran en las
          primeras dos cuadras de la Av. El Sol.
        </p>
        <h3>Cajeros Automáticos</h3>
        <p>
          La gran mayoría de restaurantes, hoteles y comercios en Cusco aceptan
          las principales tarjetas de crédito (Visa, Mastercard, American
          Express y Diners). En los pequeños lugares se recomienda tener la
          moneda local (Nuevo Sol Peruano).
        </p>
        <h3>Corriente Eléctrica</h3>
        <p>El fluido eléctrico en el Perú es de 220 voltios (60 ciclos).</p>

        {/* <ol>
          <li>We want everything to look good out of the box.</li>
          <li>
            Really just the first reason, that's the whole point of the plugin.
          </li>
          <li>
            Here's a third pretend reason though a list with three items looks
            more realistic than a list with two items.
          </li>
        </ol> */}
        {/* <blockquote>
          <p>
            Typography is pretty important if you don't want your stuff to look
            like trash. Make it good then it won't be bad.
          </p>
        </blockquote> */}
        {/* <p>
          It's probably important that images look okay here by default as well:
        </p>
        <figure>
          <img
            src="https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1045&amp;q=80"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure vel
            officiis ipsum placeat itaque neque dolorem modi perspiciatis dolor
            distinctio veritatis sapiente
          </figcaption>
        </figure>
        <p>
          Now I'm going to show you an example of an unordered list to make sure
          that looks good, too:
        </p>
        <ul>
          <li>So here is the first item in this list.</li>
          <li>In this example we're keeping the items short.</li>
          <li>Later, we'll use longer, more complex list items.</li>
        </ul>
        <p>And that's the end of this section.</p>
        <h2>Code should look okay by default.</h2>
        <p>
          I think most people are going to use{" "}
          <a href="https://highlightjs.org/">highlight.js</a> or{" "}
          <a href="https://prismjs.com/">Prism</a> or something if they want to
          style their code blocks but it wouldn't hurt to make them look{" "}
          <em>okay</em> out of the box, even with no syntax highlighting.
        </p>
        <p>
          What I've written here is probably long enough, but adding this final
          sentence can't hurt.
        </p>

        <p>Hopefully that looks good enough to you.</p>
        <h3>We still need to think about stacked headings though.</h3>
        <h4>
          Let's make sure we don't screw that up with <code>h4</code> elements,
          either.
        </h4>
        <p>
          Phew, with any luck we have styled the headings above this text and
          they look pretty good.
        </p>
        <p>
          Let's add a closing paragraph here so things end with a decently sized
          block of text. I can't explain why I want things to end that way but I
          have to assume it's because I think things will look weird or
          unbalanced if there is a heading too close to the end of the document.
        </p>
        <p>
          What I've written here is probably long enough, but adding this final
          sentence can't hurt.
        </p> */}
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Bestway || Información Útil</title>
      </Helmet>
      {renderHeader()}
      {/* <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://thumbs.dreamstime.com/b/la-plaza-principal-de-ciudad-lima-en-per%C3%BA-vista-panor%C3%A1mica-152851959.jpg"
      /> */}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  );
};

export default BlogSingleExtraInformation;
