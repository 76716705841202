import { Helmet } from "react-helmet";
import lima from "images/lima.jpg";

const BlogTermsAndConditions = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5 lock text-base text-neutral-500 sm:text-sm dark:text-neutral-400 pb-1">
          {/* <Badge href="##" color="purple" name="Traveler" /> */}
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Términos y Condiciones
          </h1>

          <p>
            <strong>
              <span>
                PROCESO DE RESERVACI&Oacute;N, PAGO Y CONFIRMACI&Oacute;N
              </span>
            </strong>
          </p>

          <ol>
            <li>
              <span>
                Las solicitudes de reservas de alojamiento, paquetes y/o
                traslados se realizan a trav&eacute;s de la web.
              </span>
            </li>
            <li>
              <span>
                Escoja el servicio que desea y luego haga click en Reservar
              </span>
            </li>
            <li>
              <span>
                Se abrir&aacute; un formulario que debe llenar de acuerdo a los
                datos que le solicitan
              </span>
            </li>
            <li>
              <span>Una vez llenados los datos haga click en Reservar</span>
            </li>
            <li>
              <span>
                Usted recibir&aacute; en su correo una respuesta
                autom&aacute;tica indicando que su solicitud est&aacute; siendo
                procesada.
              </span>
            </li>
            <li>
              <span>
                Procederemos a confirmar su reserva. La confirmaci&oacute;n
                est&aacute; sujeta a disponibilidad del servicio y a cualquier
                variaci&oacute;n en el precio por parte del proveedor.&nbsp;
              </span>
            </li>
            <li>
              <span>
                Recibir&aacute; un correo con la confirmaci&oacute;n de su
                reserva, el detalle de los servicios solicitados y el monto
                total a pagar. Adjunto encontrar&aacute; un link de pago (con
                todas las medidas de seguridad) para que proceda a realizar el
                pago. Debe llenar los datos solicitados de su tarjeta de
                cr&eacute;dito.
              </span>
            </li>
          </ol>

          <p>
            <strong>
              <span>CONDICIONES DE ALOJAMIENTOS</span>
            </strong>
          </p>
          <ul>
            <li>
              <span>
                Tarifas expresadas en US$ d&oacute;lares americanos. No incluyen
                IGV (18%)
              </span>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </li>
            <li>
              <span>Tarifas aplican s&oacute;lo&nbsp;</span>
              <span>
                para acomodaci&oacute;n del 14 al 20 de Junio. Para otra fecha
                consultar a trav&eacute;s de nuestro correo de consultas.&nbsp;
              </span>
            </li>
            <li>
              <span>
                Precios y disponibilidad confirmados a la hora de hacer la
                reserva.&nbsp;
              </span>
            </li>
            <li>
              <span>
                Las reservas ser&aacute;n confirmadas una vez realizado el pago.
              </span>
              <span>&nbsp;</span>
            </li>
            <li>
              <span>
                Las tarifas y espacios est&aacute;n sujetos a disponibilidad y
                confirmaci&oacute;n&nbsp;
              </span>
            </li>
            <li>
              <span>
                De acuerdo a D.S. N&deg; 023-023-2001-ITINCI, Titulo III,
                art&iacute;culo 22&deg;, los hu&eacute;spedes, sus
                acompa&ntilde;antes y visitantes deben registrarse en la
                recepci&oacute;n del hotel antes de ingresar al establecimiento.
                El área de recepción solicitará una copia de su DNI o pasaporte
                al momento del registro.
              </span>
            </li>
            <li>
              <span>
                Para el registro de ingreso de los pasajeros al alojamiento
                (Check in) deber&aacute;n contar con los siguientes documentos:
              </span>
              <ol>
                <li>
                  <span>Peruanos: DNI (impuesto del 18%)</span>
                </li>
                <li>
                  <span>
                    Extranjeros: Pasaporte o documento de identidad de su
                    pa&iacute;s de origen (Pa&iacute;ses de la Regi&oacute;n
                    Andina) y TAM (Tarjeta Andina de Migraciones).
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span>
                En el caso de ser extranjeros, se les exonera el IGV 18% si
                tiene una permanencia menor a los 60 d&iacute;as en los
                servicios de alojamiento y/o alimentación (no se incluyen
                bebidas alcohólicas).
              </span>
            </li>
            <li>
              <span>
                Los ciudadanos peruanos que residan m&aacute;s de 59 d&iacute;as
                en el Per&uacute; deben abonar un suplemento del 10% en concepto
                de IGV. Para estar exentos se debe presentar copia de la tarjeta
                andina de migraci&oacute;n (TAM) y sello de ingreso del
                pasaporte y su documento de residencia en otro país.
              </span>
            </li>
            <li>
              <span>
                El hotel NO se hace responsable de p&eacute;rdidas personales
                ocasionadas en las habitaciones. En caso el hu&eacute;sped tenga
                alg&uacute;n objeto de valor (joyas, dinero, computadores,
                pasaportes, boletos, etc.) se recomienda usar la caja fuerte que
                se encuentra en la habitaci&oacute;n.
              </span>
            </li>
          </ul>

          <ul>
            <li>
              <span>
                NO SHOW, SALIDA ANTICIPADA / EARLY CHECK IN, EARLY CHECK OUT
              </span>
              <ol>
                <li>
                  <span>
                    Si el hu&eacute;sped desea realizar su check out antes de la
                    fecha (Salida anticipada) o no se presenta al hotel el
                    d&iacute;a de su check in (No show) se cancelar&aacute; toda
                    reserva y se proceder&aacute; a realizar el cargo por
                    estancia completa sin reembolso. No aplican cambios ni
                    anulaciones.&nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    Todo early check-in y late check-out están sujetos a
                    disponibilidad y/o a un cargo adicional. Solicitar las
                    condiciones al Hotel en caso requiera este servicio, puede
                    incurrir en costo adicional.
                  </span>
                </li>
              </ol>
            </li>
          </ul>
          <p>
            <span>&nbsp;</span>
          </p>
          <ul>
            <li>
              <span>CANCELACIONES Y MODIFICACIONES:</span>
              <ol>
                <li>
                  <span>
                    Toda cancelaci&oacute;n de reserva con 72 horas de
                    anticipaci&oacute;n se aplicar&aacute; una penalidad del
                    valor de la primera noche de alojamiento m&aacute;s el 18%
                    de IGV.&nbsp;
                  </span>
                </li>
                <li>
                  <span>
                    No show y/o cancelaciones con menor anticipaci&oacute;n se
                    aplicar&aacute; una penalidad del 100% del total m&aacute;s
                    IGV.
                  </span>
                </li>
                <li>
                  <span>
                    Modificaci&oacute;n de fechas deber&aacute; ser solicitada
                    con al menos 48 hrs. de anticipaci&oacute;n a la fecha de
                    arribo del pasajero, v&iacute;a mail
                  </span>
                </li>
              </ol>
            </li>
          </ul>
          <p>
            <strong>
              <span>CONDICIONES DE PAQUETES&nbsp;</span>
            </strong>
          </p>

          <p>
            <strong>
              <span>LIMA Y PARACAS</span>
            </strong>
          </p>
          <ul>
            <li>
              <span>
                Precios por persona en d&oacute;lares americanos.&nbsp;
              </span>
            </li>
            <li>
              <span>
                Precios no incluyen IGV. S&oacute;lo aplica para residentes
                peruanos.
              </span>
            </li>
            <li>
              <span>
                El servicio es brindado m&iacute;nimo con 2 personas.&nbsp;
              </span>
            </li>
            <li>
              <span>
                Pasajeros viajando solos implica variaci&oacute;n en el costo.
              </span>
            </li>
            <li>
              <span>
                Precios sujetos a cambio sin previo aviso, confirmados a la hora
                de hacer la reserva.&nbsp;
              </span>
            </li>
            <li>
              <span>
                El servicio, el itinerario y los horarios est&aacute;n sujetos a
                posibles variaciones en funci&oacute;n de acontecimientos de la
                ciudad ajenos al operador.&nbsp;
              </span>
            </li>
            <li>
              <span>No reembolsable, no endosable, ni transferible.&nbsp;</span>
            </li>
            <li>
              <span>
                No se permiten cambios una vez realizado el prepago.&nbsp;
              </span>
            </li>
            <li>
              <span>
                Anulaciones o cancelaciones antes de los 30 d&iacute;as previos
                al inicio del viaje se penalizar&aacute; con gastos
                administrativos y de acuerdo a las penalidades impuestos por
                cada proveedor.
              </span>
            </li>
            <li>
              <span>
                Anulaciones o cancelaciones dentro de los 30 d&iacute;as previos
                al inicio del viaje se penalizar&aacute; al
                100%&nbsp;del&nbsp;pago&nbsp;total.
              </span>
            </li>
          </ul>

          <p>
            <strong>
              <span>CUSCO</span>
            </strong>
          </p>
          <ul>
            <li>
              <span>
                Tarifas expresadas en US$ d&oacute;lares americanos. No incluyen
                IGV (18%)
              </span>
              <strong>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
              </strong>
            </li>
            <li>
              <span>Tarifas vigentes&nbsp;</span>
              <span>para viajar del 10 al 16 de Junio.</span>
            </li>
            <li>
              <span>Vigentes para comprar hasta agotar stock.</span>
            </li>
            <li>
              <span>
                Importante: Por el aforo que brindan para visitar la ciudadela
                de Machupicchu es recomendable comprar los tickets con un mes de
                anticipaci&oacute;n de lo contrario se cobrar&iacute;a
                suplementos para la visita o posiblemente no se consigan las
                fechas solicitadas.
              </span>
            </li>
            <li>
              <span>
                Sujeto a disponibilidad al momento de pedir la reserva.
              </span>
            </li>
            <li>
              <span>Precios sujetos a variaci&oacute;n sin previo aviso.</span>
            </li>
            <li>
              <span>
                Las tarifas a&eacute;reas son cotizadas en clases
                econ&oacute;micas y solo con bolsa de mano.
              </span>
            </li>
            <li>
              <strong>
                <span>
                  Las tarifas de tren como de a&eacute;reos est&aacute;n siempre
                  sujetas a disponibilidad y variaci&oacute;n de tarifas.
                </span>
              </strong>
            </li>
            <li>
              <span>
                Precios confirmados a la hora de hacer la reserva. Debido a los
                m&uacute;ltiples cambios que ocurren diariamente en turismo
              </span>
            </li>
            <li>
              <span>
                Las reservas ser&aacute;n confirmadas una vez realizado el pago.
              </span>
            </li>
            <li>
              <span>
                Anulaciones o cancelaciones antes de los 30 d&iacute;as previos
                al inicio del viaje se penalizar&aacute; con gastos
                administrativos y de acuerdo a las penalidades impuestos por
                cada proveedor.
              </span>
            </li>
            <li>
              <span>
                Anulaciones o cancelaciones dentro de los 30 d&iacute;as previos
                al inicio del servicio se penalizar&aacute; al 100% del pago
                total.&nbsp;
              </span>
            </li>
            <li>
              <span>
                No reembolsable, no endosable, ni transferible. No se permiten
                cambios una vez realizado el prepago.&nbsp;
              </span>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <strong>
              <span>CONDICIONES DE TRASLADOS</span>
            </strong>
          </p>
          <ul>
            <li>
              <span>
                Tarifas expresadas en US$ d&oacute;lares americanos. No incluyen
                IGV (18%)
              </span>
              <strong>
                <span>
                  .&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;
                </span>
              </strong>
            </li>
            <li>
              <span>Tarifas s</span>
              <span>
                ujetas a variaci&oacute;n sin previo aviso por subida de
                combustible, baja considerable del d&oacute;lar e incremento en
                el estacionamiento del aeropuerto.
              </span>
            </li>
            <li>
              <span>
                No cubre robo o p&eacute;rdida del equipaje o pertenencias
                personales (responsabilidad exclusiva de pasajero durante el
                desarrollo del servicio).&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </span>
            </li>
            <li>
              <span>
                No cubre utilizaci&oacute;n de la unidad para devoluci&oacute;n
                de cualquier objeto olvidado.&nbsp; &nbsp; &nbsp;&nbsp;
              </span>
            </li>
            <li>
              <span>
                No cubre la revisi&oacute;n de la unidad al final del servicio,
                siendo responsabilidad de los pasajeros.
              </span>
            </li>
            <li>
              <span>
                No cubre demoras por responsabilidad de los pasajeros en el
                horario de inicio del servicio.&nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp;
              </span>
              <strong>
                <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
              </strong>
            </li>
            <li>
              <span>
                No reembolsable, no endosable, ni transferible. No se permiten
                cambios una vez realizado el prepago.&nbsp;
              </span>
            </li>
            <li>
              <span>
                Anulaciones o cancelaciones dentro de los 30 d&iacute;as previos
                al inicio del servicio se penalizar&aacute; al 100% del pago
                total.&nbsp;
              </span>
            </li>
          </ul>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      ></div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Bestway || Información Útil</title>
      </Helmet>
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  );
};

export default BlogTermsAndConditions;
