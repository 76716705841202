import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import GuestsInput, { GuestsInputProps } from "./GuestsInput";
import { FocusedInputShape } from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import moment from "moment";
import { FC } from "react";
import { useParams } from "react-router-dom";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "Tokyo, Jappan";
const defaultDateRange = {
  startDate: moment(),
  endDate: moment().add(4, "days"),
};
const defaultGuestValue: GuestsInputProps["defaultValue"] = {
  guestAdults: 2,
  guestChildren: 2,
  guestInfants: 1,
};

const StaySearchForm: FC<StaySearchFormProps> = ({
  haveDefaultValue = false,
}) => {
  const { location, checkin, checkout, guests } = useParams();

  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: moment("2023-06-15"),
    endDate: moment("2023-06-18"),
  });
  const [locationInputValue, setLocationInputValue] = useState("LIM");
  const [guestValue, setGuestValue] = useState<
    GuestsInputProps["defaultValue"]
  >({
    guestAdults: 1,
    guestChildren: 0,
    guestInfants: 0,
  });
  /**{
  guestAdults: 2,
  guestChildren: 0,
  guestInfants: 0,
} */

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );

  //
  useEffect(() => {
    if (haveDefaultValue) {
      setDateRangeValue({
        startDate: moment(checkin),
        endDate: moment(checkout),
      });
      setLocationInputValue(location ?? "Lima, Peru");
      setGuestValue({
        guestAdults: +(guests ?? "1"),
        guestChildren: 0,
        guestInfants: 0,
      });
    }
  }, []);
  //

  const handleLocationOnChange = (e: any) => {
    setLocationInputValue(e);
  };

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput
          defaultValue={locationInputValue}
          onChange={(e) => handleLocationOnChange(e)}
          onInputDone={() => setDateFocused("startDate")}
          className="flex-[1.5]"
        />
        <StayDatesRangeInput
          defaultValue={dateRangeValue}
          defaultFocus={dateFocused}
          onChange={(data) => setDateRangeValue(data)}
          className="flex-[2]"
        />
        <GuestsInput
          defaultValue={guestValue}
          location={locationInputValue}
          checkin={dateRangeValue.startDate?.format("YYYY-MM-DD")}
          checkout={dateRangeValue.endDate?.format("YYYY-MM-DD")}
          guests={guestValue.guestAdults?.toString()}
          onChange={(data) => setGuestValue(data)}
          className="flex-[1.2]"
        />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
