import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "Seguridad y confianza",
    subHeading:
      "Como empresa velamos por que nuestros pasajeros no se vean afectados de ninguna manera antes, durante ni después de su viaje. Damos la confianza a nuestros pasajeros y la transparencia necesaria para que disfruten de su viaje de la mejor manera.",
  },
  {
    id: "2",
    heading: "Atención permanente",
    subHeading:
      "Nos preocupamos por que nuestros pasajeros siempre se vean beneficiados en todo momento, velamos por el bienestar de nuestro pasajero antes, durante y después de su viaje. Queremos ser vistos como un aliado durante su viaje.",
  },
  {
    id: "3",
    heading: "Experiencia",
    subHeading:
      "Nuestra experiencia nos avala. Con más de 20 años de experiencia en el mercado, conocemos las mejores opciones para que nuestros pasajeros realicen sus viajes, los disfruten y cumplan sus objetivos. Sabemos el dónde, el cuándo, el cómo y el por qué para todo lo relacionado a viajes.",
  },
  {
    id: "4",
    heading: "Calidad",
    subHeading:
      "En Best Way Perú, el cliente es nuestra prioridad absoluta, por ello la confianza que depositan en Best Way Perú es vital para brindarles la mejor calidad en nuestros productos y servicios, y obtener así la máxima satisfacción de nuestros clientes.",
  },
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading desc="Buscamos brindar a nuestros pasajeros todos los servicios relacionados a su viaje, sea de negocios o de placer. Como equipo, brindamos un servicio personalizado y de calidad con el fin de que nuestro pasajero viaje sin preocupaciones.">
        🚀 ¿Por qué nosotros?
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;
