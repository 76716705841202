import React from "react";
import MyRouter from "routers/index";
import { GlobalAppProvider } from "store/app-context";

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <GlobalAppProvider>
        <MyRouter />
      </GlobalAppProvider>
    </div>
  );
}

export default App;
