import { DispatchObject } from './dispatch-object';
import produce, { Draft } from 'immer';
import { Dispatch } from 'react';
import { TaxonomyType } from 'data/types';
import { AccomodationInterface } from 'domain/accomodation-item';
import { Entry } from 'contentful';
import { PackagesIntereface } from 'domain/packages-item';
// import { MyCommunitiesModel } from '../pages/home/_homeModels';

export type DispatchApp = Dispatch<DispatchObject<AppActions>>;

export interface ErrorPageInterface {
  icon: string;
  title: string;
  subtitle: string;
  primaryAction(): void;
}

export enum AppActions {
  IsFetching = 'IS_FETCHING',
  FinishedFetching = 'FINISHED_FETCHING',
  ErrorFetching = 'ERROR_FETCHING',
  ClearError = 'CLEAR_ERROR',
  ClearData = 'CLEAR_DATA',

  GetMyAddContent = 'GET_MY_ADD_CONTENT',
  GetMyListAccomodations = 'GET_MY_LIST_ACCOMODATIONS',
  GetMyPackagesList = 'GET_PACKAGE_LIST',
}

export interface AppState {
  isFetching: boolean;
  pendingFetches: number;
  error: null | ErrorPageInterface;
  myAddContent: TaxonomyType[];
  selectedAccomodation:  Entry<AccomodationInterface>;
  accomodationList: Entry<AccomodationInterface>[];
  packagesList: Entry<PackagesIntereface>[];
}

export const initialState: AppState = {
  isFetching: false,
  pendingFetches: 0,
  error: null,
  myAddContent: [],
  selectedAccomodation: {} as Entry<AccomodationInterface>,
  accomodationList: [] as Entry<AccomodationInterface>[],
  packagesList: [] as Entry<PackagesIntereface>[],
};

export const appReducer = produce((draft: Draft<AppState>, { type, payload }: any) => {
  switch (type) {
    case AppActions.IsFetching:
      draft.pendingFetches += 1;
      draft.isFetching = true;
      draft.error = null;
      break;
    case AppActions.FinishedFetching:
      draft.pendingFetches -= 1;
      draft.isFetching = false;
      break;
    case AppActions.ErrorFetching:
      draft.pendingFetches = 0;
      draft.isFetching = false;
      draft.error = payload as ErrorPageInterface;
      break;
    case AppActions.ClearError:
      draft.error = null;
      break;
    case AppActions.GetMyAddContent:
      draft.myAddContent = payload as TaxonomyType[];
      draft.error = null;
      break;
    case AppActions.GetMyAddContent:
      draft.selectedAccomodation = payload as Entry<AccomodationInterface>;
      draft.error = null;
      break;
    case AppActions.GetMyListAccomodations:
      draft.accomodationList = payload as Entry<AccomodationInterface>[];
      draft.error = null;
      break;
    case AppActions.GetMyPackagesList:
      draft.packagesList = payload as Entry<PackagesIntereface>[];
      draft.error = null;
      break;
    case AppActions.ClearData:
      return initialState;
    default:
      throw new Error(`Unknown type: ${type}`);
  }
});
