import { createClient, PlainClientAPI } from 'contentful-management';
import { ReservationInterface } from 'domain/contentful/reservation-item';
import { v4 } from 'uuid';
import ClientInterface from '../../domain/contentful/client';
import { ShoppingCartItemInterface } from '../../domain/shopping-cart-item';

let client: PlainClientAPI;

interface CreateContentfulManagementInterface {
  spaceId: string;
  accessToken: string;
  environmentId: string;
}

interface ContentfulEntryKeys {
  [key: string]: string | number | Object;
}

interface OrderInterface {
  reservation: ReservationInterface;
}

const generateContentfulEntryKeys = (item: ContentfulEntryKeys) => {
  return Object.keys(item).reduce((entry: { [key: string]: ContentfulEntryKeys }, key) => {
    entry[key] = {
      'en-US': item[key],
    };
    return entry;
  }, {});
};

export const createContentfulManagementClient = ({ spaceId, accessToken, environmentId }: CreateContentfulManagementInterface): void => {
  /* istanbul ignore else */
  if (!client) {
    client = createClient(
      { accessToken },
      {
        type: 'plain',
        defaults: {
          spaceId,
          environmentId,
        },
      }, 
    );
  }
};

export const createPurchaseOrderInContentful = async <T>({ reservation }: OrderInterface): Promise<any> => {
  if (!client) {
    throw new Error('You need to create a contentful client using "createContentfulClient"');
  }

  const product = await client.entry.create(
    { contentTypeId: 'Reservation' },
    {
      fields:  {
        reservationType: {
          'en-US': v4().slice(0,8),
        },
        ...generateContentfulEntryKeys( {...reservation})
      }
      ,
    },
  ).then((res) => {
    console.log('[EV][Res]',res)
  }).catch((er) => {
    console.log('[EV][err]',er)
  });





};