import React, { FC, Fragment, useEffect, useState } from "react";
import GuestsInput, {
  GuestsInputProps,
} from "components/HeroSearchForm/GuestsInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { FocusedInputShape } from "react-dates";

import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { getContentFromContentful } from "utils/contentful/contentful";
import { Entry } from "contentful";
import { Link, useParams } from "react-router-dom";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ModalPhotos from "containers/ListingDetailPage/ModalPhotos";
import MobileFooterSticky from "containers/ListingDetailPage/MobileFooterSticky";
import { BLOCKS } from "@contentful/rich-text-types";
import { PackagesIntereface } from "domain/packages-item";
import { useGlobalAppDispatch, useGlobalAppState } from "store/app-context";
import FormItem from "containers/PageAddListing1/FormItem";
import Select from "shared/Select/Select";
import { ContentfulAccomodations } from "domain/accomodation-item";
import Label from "components/Label/Label";
import { ContentfulRooms } from "domain/room-item";

export interface ListingPackagePageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const PHOTOS: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
];

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        target: { fields },
      },
    }: any): any =>
      `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
    [BLOCKS.EMBEDDED_ENTRY]: ({
      data: {
        target: { fields },
      },
    }: any): any =>
      `<ins class="adsbygoogle"  style="display:block; text-align:center;" data-ad-layout="in-article" data-ad-format="fluid"     data-ad-client="${fields.dataAddClient}" data-ad-slot="8421862720"></ins>`,
  },
};

const ListingPackagePage: FC<ListingPackagePageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  let { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment("2023-06-11"),
    endDate: moment("2023-06-15"),
  });
  const [accomodation, setAccomodation] = useState<Entry<PackagesIntereface>>();
  const [selectedAccomodationId, setSelectedAccomodationId] =
    useState<String>();
  const [selectedRoomId, setSelectedRoomId] = useState<String>();
  const [selectedAccomodation, setSelectedAccomodation] =
    useState<ContentfulAccomodations | null>();
  const [selectedRoom, setSelectedRoom] = useState<ContentfulRooms | null>();

  useEffect(() => {
    setSelectedDate({
      startDate: moment("2023-06-11"),
      endDate: moment("2023-06-15"),
    });

    if (id) {
      getContentFromContentful<any>(id).then((content) => {
        setAccomodation(content);
        if (content?.fields.accomodation) {
          setSelectedAccomodation(content?.fields.accomodation[0]);
          setSelectedAccomodationId(content?.fields.accomodation[0].sys.id);
          setSelectedRoom(content?.fields.accomodation[0].fields.rooms[0]);
          setSelectedRoomId(
            content?.fields.accomodation[0].fields.rooms[0].sys.id
          );
        }
      });
    }
  }, []);

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const [guestValue, setGuestValue] = useState<
    GuestsInputProps["defaultValue"]
  >({
    guestAdults: 1,
    guestChildren: 0,
    guestInfants: 0,
  });

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={"Paquete"} />
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {accomodation && accomodation.fields.name}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {accomodation?.fields.city}</span>
          </span>
        </div>

        {/* 4 */}
        {/* <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Información del Tour</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {accomodation?.fields.description && (
          <div
            className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(
                accomodation.fields.description,
                options
              ),
            }}
          ></div>
        )}
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="">
        {/* <Badge href="##" color="purple" name="Traveler" /> */}
        <span className="block text-base text-neutral-500 sm:text-sm dark:text-neutral-400 pb-1">
          TÉRMINOS Y CONDICIONES PARA PAQUETES DE VIAJE (LIMA Y PARACAS):
          Precios por persona en dólares americanos. Precios no incluyen IGV. El
          servicio es brindado mínimo con 2 personas. Pasajeros viajando solos
          implica variación en el costo. Precios sujetos a cambio sin previo
          aviso, confirmados a la hora de hacer la reserva. El servicio, el
          itinerario y los horarios están sujetos a posibles variaciones en
          función de acontecimientos de la ciudad ajenos al operador.
          Anulaciones: No reembolsable, no endosable, ni transferible. No se
          permiten cambios una vez realizado el prepago. Anulaciones o
          cancelaciones antes de los 30 días previos al inicio del viaje se
          penalizará con gastos administrativos y de acuerdo a las penalidades
          impuestos por cada proveedor. Anulaciones o cancelaciones dentro de
          los 30 días previos al inicio del viaje se penalizará al 100% del pago
          total.
        </span>
        <br />
        <span className="block text-base text-neutral-500 sm:text-sm dark:text-neutral-400 pb-1">
          TÉRMINOS Y CONDICIONES PARA PAQUETES DE VIAJE (CUSCO): Precios por
          persona en dólares americanos. Vigentes para viajar del 10 al 16 de
          Junio. Vigentes para comprar hasta agotar stock. Importante: Por el
          aforo que brindan para visitar la ciudadela de Machu Picchu es
          recomendable comprar los tickets con un mes de anticipación de lo
          contrario se cobraría suplementos para la visita o posiblemente no se
          consigan las fechas solicitadas. Sujeto a disponibilidad al momento de
          pedir la reserva. Precios sujetos a variación sin previo aviso. Las
          tarifas aéreas son cotizadas en clases económicas y solo con bolsa de
          mano. Las tarifas de tren como de aéreos están siempre sujetas a
          disponibilidad y variación de tarifas. Debido a los múltiples cambios
          que ocurren diariamente en turismo estos precios deben ser confirmados
          a la hora de hacer la reserva. Anulaciones o cancelaciones antes de
          los 30 días previos al inicio del viaje se penalizara con gastos
          administrativos y de acuerdo a las penalidades impuestos por cada
          proveedor. Anulaciones o cancelaciones dentro de los 30 días previos
          al inicio del viaje se penalizara al 100% del pago total. No
          reembolsable, no endosable, ni transferible. No se permiten cambios
          una vez realizado el prepago.
        </span>
      </div>
    );
  };

  const accomodationChange = (event: any) => {
    setSelectedAccomodationId(event.target.value);
    const acc = accomodation?.fields.accomodation?.filter(
      (f) => f.sys.id === event.target.value
    )[0];
    setSelectedAccomodation(acc);

    if (acc?.fields.rooms) {
      setSelectedRoom(acc?.fields.rooms[0]);
      setSelectedRoomId(acc?.fields.rooms[0].sys.id);
    }
  };
  const roomChange = (event: any) => {
    setSelectedRoomId(event.target.value);
    setSelectedRoom(
      selectedAccomodation?.fields.rooms?.filter(
        (f) => f.sys.id === event.target.value
      )[0]
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {!selectedAccomodation && `$${accomodation?.fields.price}`}
          </span>
          {/* <StartRating
            point={accomodation?.fields.accomodationStars}
            reviewCount={-1}
          /> */}
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            onlyCheckinDate={accomodation?.fields.oneDayPackages}
            disabled={false}
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
            onChange={(date) => setSelectedDate(date)}
            fieldClassName="p-3"
            defaultValue={selectedDate}
            anchorDirection={"right"}
            className="nc-ListingPackagePage__stayDatesRangeInput flex-1"
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            className="nc-ListingPackagePage__guestsInput flex-1"
            fieldClassName="p-3"
            onChange={(data) => setGuestValue(data)}
            defaultValue={guestValue}
            hasButtonSubmit={false}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          {accomodation?.fields.accomodation?.length && (
            <>
              <FormItem label="Hotel">
                <Select onChange={accomodationChange}>
                  {accomodation.fields.accomodation?.map((mp) => (
                    <option
                      key={mp.sys.id}
                      value={mp.sys.id}
                    >{`${mp.fields.name}`}</option>
                  ))}
                </Select>
              </FormItem>
              {selectedAccomodation && (
                <FormItem label="Tipo de Habitación">
                  <Select onChange={roomChange}>
                    {selectedAccomodation.fields.rooms?.map((item) => (
                      <option
                        key={item.sys.id}
                        value={item.sys.id}
                      >{`${item.fields.roomName} - $${item.fields.nightPrice}`}</option>
                    ))}
                  </Select>
                </FormItem>
              )}
              <Link
                className="flex space-x-3"
                to={`/listing-stay-detail/${selectedAccomodation?.sys.id}`}
                style={{ marginLeft: "auto" }}
              >
                <i className={`text-3xl las la-search`}></i>
                ver Hotel
              </Link>
            </>
          )}
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Subtotal</span>
            {accomodation?.fields.accomodation?.length ? (
              <span>{`$${
                (selectedRoom?.fields.nightPrice ?? 0) *
                (guestValue.guestAdults ?? 1)
              }`}</span>
            ) : (
              <span>{`$${
                +(accomodation?.fields.price ?? 0) *
                (guestValue.guestAdults ?? 1)
              }`}</span>
            )}
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            {/* <span>{`$${
              +(accomodation?.fields.price ?? 0) * (guestValue.guestAdults ?? 1)
            }`}</span> */}
            {accomodation?.fields.accomodation?.length ? (
              <span>{`$${
                (selectedRoom?.fields.nightPrice ?? 0) *
                (guestValue.guestAdults ?? 1)
              }`}</span>
            ) : (
              <span>{`$${
                +(accomodation?.fields.price ?? 0) *
                (guestValue.guestAdults ?? 1)
              }`}</span>
            )}
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary
          href={`/checkout/packages/${selectedDate.startDate?.format(
            "YYYY-MM-DD"
          )}/${selectedDate.endDate?.format("YYYY-MM-DD")}/${
            guestValue.guestAdults
          }/${
            accomodation?.fields.accomodation?.length
              ? `${selectedRoomId}/${selectedAccomodationId}/${id}`
              : id
          }`}
        >
          Reservar
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingPackagePage ${className}`}
      data-nc-id="ListingPackagePage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              {accomodation?.fields.images && (
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  // src={PHOTOS[0]}
                  src={`${accomodation?.fields?.images[0]?.fields?.file?.url}`}
                />
              )}
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {accomodation?.fields.images &&
              accomodation?.fields.images
                .filter((_, i) => i >= 1 && i < 5)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 3 ? "hidden sm:block" : ""
                    }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={item?.fields?.file?.url || ""}
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Ver Imagenes
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        {accomodation?.fields.images && (
          <ModalPhotos
            imgs={accomodation?.fields.images?.map(
              (im) => im?.fields?.file?.url ?? ""
            )}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-ListingPackagePage-modalPhotos"
          />
        )}
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && (
        <MobileFooterSticky
          totalPrice={+(accomodation?.fields.price ?? 0)}
          guests={guestValue}
          selectedDatesInp={selectedDate}
        />
      )}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}
          <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Hoteles Sugeridos"
              subHeading="Hoteles destacados en Lima, Perú"
              categoryCardType="card4"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName={"ListingPackagePage1"}
            />
          </div>

          {/* SECTION */}
          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
        </div>
      )}
    </div>
  );
};

export default ListingPackagePage;
