import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import moment from "moment";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import Select from "shared/Select/Select";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalAppDispatch, useGlobalAppState } from "store/app-context";
import { Entry } from "contentful";
import { AccomodationInterface } from "domain/accomodation-item";
import { getContentFromContentful } from "utils/contentful/contentful";
import { AppActions } from "store/reducer";
import { ContentfulRooms } from "domain/room-item";
import { ReservationInterface } from "domain/contentful/reservation-item";
import { createPurchaseOrderInContentful } from "utils/contentful/contentful-management";

export interface CheckOutFormProps {
  className?: string;
}

const CheckOutForm: FC<CheckOutFormProps> = ({ className = "" }) => {
  let { formtype, checkin, checkout, guests, roomid, accomodationid, id } =
    useParams();

  const [rangeDates, setRangeDates] = useState<DateRage>({
    startDate: moment(checkin),
    endDate: moment(checkout),
  });
  const [allGuests, setallGuests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 0,
    guestInfants: 0,
  });
  const [accomodation, setAccomodation] = useState<Entry<any>>();
  const [price, setPrice] = useState<string>("0");
  const [nightPrice, setNightPrice] = useState<string>("0");
  const [selectedRoomObj, setSelectedRoomObj] =
    useState<ContentfulRooms | null>();

  const [name, setName] = useState<string>("");

  const dispatch = useGlobalAppDispatch();
  const { selectedAccomodation } = useGlobalAppState();
  let navigate = useNavigate();

  const [form, setForm] = useState<ReservationInterface>(
    {} as ReservationInterface
  );

  const huespedes = Array(allGuests.guestAdults);
  const huespedesPackages = Array(allGuests.guestAdults);

  const priceCalculator = () => {
    switch (accomodation?.sys.contentType.sys.id) {
      case "packages":
        if (accomodation?.fields.accomodation) {
          //If is with hotel and room
          const deltaPrice = accomodation.fields.accomodation
            .filter((f: any) => f.sys.id === accomodationid)[0]
            ?.fields.rooms.filter((ff: any) => ff.sys.id == roomid)[0]
            ?.fields.nightPrice;
          setPrice((deltaPrice * +(guests ?? 0)).toString());
          setNightPrice(deltaPrice.toString());
          return;
          // return `$${deltaPrice * +(guests ?? 0)}`;
        }
        setPrice(`${accomodation.fields.price * +(guests ?? 0)}`);
        setNightPrice(`${accomodation.fields.price}`);
        return;
        // return `$${accomodation.fields.price * +(guests ?? 0)}`;
        break;
      case "Accomodation":
        const deltaPrice =
          +(selectedRoomObj?.fields?.nightPrice ?? 0) *
          +(rangeDates?.endDate?.diff(rangeDates.startDate, "days") ?? 3);
        setPrice(deltaPrice.toString());
        setNightPrice(deltaPrice.toString());
        return;
        // return `$${deltaPrice}`;
        break;
      default:
        setPrice("err");
        return;
      // return 2000;
    }
    // return 100;
  };

  useEffect(() => {
    setallGuests({
      guestAdults: +(guests ?? "2"),
      guestChildren: 0,
      guestInfants: 0,
    });

    setRangeDates({
      startDate: moment(checkin),
      endDate: moment(checkout),
    });

    if (selectedAccomodation?.fields) {
      setAccomodation(selectedAccomodation);
      setSelectedRoomObj(
        selectedAccomodation.fields?.rooms?.filter(
          (f) => f.sys.id === roomid
        )[0]
      );
      // priceCalculator();
      return;
    }

    if (id) {
      getContentFromContentful<any>(id).then((content) => {
        if (content.sys.contentType.sys.id === "packages") {
          setSelectedRoomObj(content);
        } else {
          setSelectedRoomObj(
            content.fields?.rooms?.filter((f: any) => f.sys.id == roomid)[0]
          );
        }
        setAccomodation(content);
        dispatch({
          type: AppActions.GetMyAddContent,
          payload: content,
        });
        // priceCalculator();
      });
    }
  }, []);

  useEffect(() => {
    priceCalculator();
    setName(accomodation?.fields?.name);
  }, [accomodation]);

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage
                src={`${
                  accomodation?.fields?.images &&
                  accomodation?.fields?.images?.length > 0
                    ? accomodation?.fields?.images[0].fields.file.url
                    : ""
                }`}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {accomodation && accomodation.fields?.name}
              </span>
              <span className="text-base font-medium mt-1 block">
                {(accomodation && accomodation.fields?.accomodationType) ??
                  "Paquete"}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {selectedRoomObj?.fields?.roomName}
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            {accomodation?.fields.accomodationStars && (
              <StartRating
                point={accomodation?.fields.accomodationStars ?? -1}
                reviewCount={-1}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Precio</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              ${nightPrice}
              {/* {selectedRoomObj?.fields?.nightPrice ??
                accomodation?.fields.price}{" "} */}
              {!accomodation?.fields.oneDayPackages &&
                `x ${
                  rangeDates.endDate?.diff(rangeDates?.startDate, "days") ?? 0
                } noches`}
            </span>
            <span>${price}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Cargos extras</span>
            <span>$0</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>${price}</span>
            {/* <span>{`$${
              (selectedRoomObj?.fields?.nightPrice
                ? selectedRoomObj.fields?.nightPrice
                : accomodation?.fields?.nightPriceAvg ??
                  accomodation?.fields.price) *
              (rangeDates.endDate?.diff(rangeDates?.startDate, "days") ?? 3)
            }`}</span> */}
          </div>
        </div>
      </div>
    );
  };

  const formTypeRender = (item: any) => {
    switch (item) {
      case "accomodation-reservation":
        return "Hospedaje";
        break;
      case "packages":
        return "Paquetes";
        break;
      default:
        return item;
        break;
    }
  };

  const submitReserva = (event: any) => {
    event.preventDefault();
    const extras = {
      package: {
        sys: {
          type: "Link",
          linkType: "Entry",
          id: "",
        },
      },
      hotel: {
        sys: {
          type: "Link",
          linkType: "Entry",
          id: "",
        },
      },
      room: {
        sys: {
          type: "Link",
          linkType: "Entry",
          id: "",
        },
      },
    };

    if (id) {
      extras.package.sys.id = id;
    }
    if (roomid) {
      extras.hotel.sys.id = roomid;
    }
    if (accomodationid) {
      extras.room.sys.id = accomodationid;
    }
    let hues = huespedes;
    if (!hues[0]?.nombre) {
      hues = huespedesPackages;
    }
    createPurchaseOrderInContentful({
      reservation: {
        ...form,
        ...extras,
        reservationInfo: hues,
        typeOfreservation: formTypeRender(formtype),
        startReservationDate: rangeDates.startDate?.format("YYYY-MM-DD"),
        endReservationDate: rangeDates.endDate?.format("YYYY-MM-DD"),
        price: price,
        name: name,
      },
    });

    navigate(
      `/pay-done/${checkin}/${checkout}/${guests}/${roomid}/${id}/${price}`
    );
  };

  const renderAccomodationForm = () => {
    return (
      <form
        onSubmit={submitReserva}
        className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800"
      >
        <h4 className="text-xl font-semibold">Datos del Contacto</h4>
        <div className="mt-6">
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>Nombre</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, firstName: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Apellido</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, lastName: e.currentTarget.value })
                }
              />
            </div>
          </div>
          <div className="space-y-1">
            <Label>Nacionalidad</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, nationality: e.currentTarget.value })
              }
            />
          </div>
          <div className="space-y-1">
            <Label>Dirección</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, address: e.currentTarget.value })
              }
            />
          </div>
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>País</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, country: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Ciudad</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, city: e.currentTarget.value })
                }
              />
            </div>
          </div>
          <div className="space-y-1">
            <Label>Celular</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, phone: e.currentTarget.value })
              }
            />
          </div>
          <div className="space-y-1">
            <Label>Email</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, email: e.currentTarget.value })
              }
            />
          </div>
          <div className="space-y-1">
            <Label>Hora de Llegada al Hotel</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, arrivalHotelTime: e.currentTarget.value })
              }
            />
          </div>
        </div>
        <br />
        <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
        <br />
        {Array.from(Array(allGuests.guestAdults), (e, i) => {
          return (
            <div key={`acc-${i}`}>
              <h4 className="text-xl font-semibold">Huesped {i + 1}</h4>
              <div className="mt-6">
                <div className="flex space-x-5  ">
                  <div className="flex-1 space-y-1">
                    <Label>Nombre</Label>
                    <Input
                      defaultValue=""
                      required={true}
                      onChange={(e) => {
                        huespedes[i] = {
                          ...huespedes[i],
                          nombre: e.currentTarget.value,
                        };
                      }}
                    />
                  </div>
                  <div className="flex-1 space-y-1">
                    <Label>Apellido</Label>
                    <Input
                      defaultValue=""
                      required={true}
                      onChange={(e) => {
                        huespedes[i] = {
                          ...huespedes[i],
                          apellido: e.currentTarget.value,
                        };
                      }}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <Label>Nacionalidad</Label>
                  <Input
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedes[i] = {
                        ...huespedes[i],
                        nacionalidad: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>Código Herbalife</Label>
                  <Input
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedes[i] = {
                        ...huespedes[i],
                        codigo_herbalife: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>Dirección</Label>
                  <Input
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedes[i] = {
                        ...huespedes[i],
                        direccion: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="flex space-x-5  ">
                  <div className="flex-1 space-y-1">
                    <Label>País</Label>
                    <Input
                      defaultValue=""
                      required={true}
                      onChange={(e) => {
                        huespedes[i] = {
                          ...huespedes[i],
                          pais: e.currentTarget.value,
                        };
                      }}
                    />
                  </div>
                  <div className="flex-1 space-y-1">
                    <Label>Ciudad</Label>
                    <Input
                      defaultValue=""
                      required={true}
                      onChange={(e) => {
                        huespedes[i] = {
                          ...huespedes[i],
                          ciudad: e.currentTarget.value,
                        };
                      }}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <Label>Celular</Label>
                  <Input
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedes[i] = {
                        ...huespedes[i],
                        celular: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>Email</Label>
                  <Input
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedes[i] = {
                        ...huespedes[i],
                        email: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>Hora de Llegada al Hotel</Label>
                  <Input
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedes[i] = {
                        ...huespedes[i],
                        hora_de_llegada_al_hotel: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>Si seleccionó habitación doble señale: </Label>
                  <Select
                    className="mt-1.5"
                    onChange={(e) => {
                      huespedes[i] = {
                        ...huespedes[i],
                        tipo_de_cama: e.currentTarget.value,
                      };
                    }}
                  >
                    <option value="2-camas">2 Camas</option>
                    <option value="cama-matrimonial">Cama Matrimonial</option>
                  </Select>
                </div>
                <div className="space-y-1">
                  <Label>Información Adicional</Label>
                  <Textarea
                    placeholder="..."
                    onChange={(e) => {
                      huespedes[i] = {
                        ...huespedes[i],
                        informacion_adicional: e.currentTarget.value,
                      };
                    }}
                  />
                  <span className="text-sm text-neutral-500 block"></span>
                </div>
              </div>
            </div>
          );
        })}
        <div className="pt-8">
          {/* <ButtonPrimary href={"/pay-done"}>Reservar</ButtonPrimary> */}
          <ButtonPrimary
          // href={`/pay-done/${checkin}/${checkout}/${guests}/${roomid}/${id}`}
          // onClick={submitReserva}
          >
            Reservar
          </ButtonPrimary>
        </div>
      </form>
    );
  };
  const renderPackagesForm = () => {
    return (
      <form
        onSubmit={submitReserva}
        className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800"
      >
        <h4 className="text-xl font-semibold">Datos del Contacto</h4>
        <div className="mt-6">
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>Nombre</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, firstName: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Apellido</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, lastName: e.currentTarget.value })
                }
              />
            </div>
          </div>
          <div className="space-y-1">
            <Label>Nacionalidad</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, nationality: e.currentTarget.value })
              }
            />
          </div>
          <div className="space-y-1">
            <Label>Dirección</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, address: e.currentTarget.value })
              }
            />
          </div>
          <div className="space-y-1">
            <Label>Hotel</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, airportHotel: e.currentTarget.value })
              }
            />
          </div>
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>País</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, country: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Fecha de Nacimiento</Label>
              <Input
                type="date"
                defaultValue="MM/YY"
                required={true}
                onChange={(e) =>
                  setForm({
                    ...form,
                    birthday: e.currentTarget.value.toString(),
                  })
                }
              />
            </div>
          </div>
          <div className="space-y-1">
            <Label>Celular</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, phone: e.currentTarget.value })
              }
            />
          </div>
          <div className="space-y-1">
            <Label>Email</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, email: e.currentTarget.value })
              }
            />
          </div>
          <div className="space-y-1">
            <Label>Servicio para Discapacitados</Label>
            <Select
              className="mt-1.5"
              onChange={(e) =>
                setForm({ ...form, disabilityServices: e.currentTarget.value })
              }
            >
              <option value="No">No</option>
              <option value="Si">Si</option>
            </Select>
          </div>
        </div>
        <br />
        <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
        <br />
        {Array.from(Array(allGuests.guestAdults), (e, i) => {
          return (
            <div key={`pack-${i}`}>
              <h4 className="text-xl font-semibold">Pasajero {i + 1}</h4>
              <div className="mt-6">
                <div className="flex space-x-5  ">
                  <div className="flex-1 space-y-1">
                    <Label>Nombre</Label>
                    <Input
                      defaultValue=""
                      required={true}
                      onChange={(e) => {
                        huespedesPackages[i] = {
                          ...huespedesPackages[i],
                          nombre: e.currentTarget.value,
                        };
                      }}
                    />
                  </div>
                  <div className="flex-1 space-y-1">
                    <Label>Apellido</Label>
                    <Input
                      defaultValue=""
                      required={true}
                      onChange={(e) => {
                        huespedesPackages[i] = {
                          ...huespedesPackages[i],
                          apellido: e.currentTarget.value,
                        };
                      }}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <Label>Código Herbalife</Label>
                  <Input
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedesPackages[i] = {
                        ...huespedesPackages[i],
                        codigo_herbalife: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>País</Label>
                  <Input
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedesPackages[i] = {
                        ...huespedesPackages[i],
                        pais: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>Tipo de Documento</Label>
                  <Select
                    className="mt-1.5"
                    onChange={(e) => {
                      huespedesPackages[i] = {
                        ...huespedesPackages[i],
                        tipo_de_documento: e.currentTarget.value,
                      };
                    }}
                  >
                    <option value="pasaporte">Pasaporte</option>
                    <option value="dni">DNI</option>
                  </Select>
                </div>
                <div className="space-y-1">
                  <Label>Número de Documento</Label>
                  <Input
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedesPackages[i] = {
                        ...huespedesPackages[i],
                        numero_de_documento: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>Fecha de nacimiento</Label>
                  <Input
                    type="date"
                    defaultValue="MM/YY"
                    required={true}
                    onChange={(e) => {
                      huespedesPackages[i] = {
                        ...huespedesPackages[i],
                        fecha_de_nacimiento: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>Celular</Label>
                  <Input
                    type="number"
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedesPackages[i] = {
                        ...huespedesPackages[i],
                        celular: e.currentTarget.value,
                      };
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label>Email</Label>
                  <Input
                    type="email"
                    defaultValue=""
                    required={true}
                    onChange={(e) => {
                      huespedesPackages[i] = {
                        ...huespedesPackages[i],
                        email: e.currentTarget.value,
                      };
                    }}
                  />
                </div>

                <div className="space-y-1">
                  <Label>Servicio para Discapacitados</Label>
                  <Select
                    className="mt-1.5"
                    onChange={(e) => {
                      huespedesPackages[i] = {
                        ...huespedesPackages[i],
                        servicio_para_discapacitados: e.currentTarget.value,
                      };
                    }}
                  >
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                  </Select>
                </div>
              </div>
            </div>
          );
        })}
        <div className="pt-8">
          {/* <ButtonPrimary href={"/pay-done"}>Reservar</ButtonPrimary> */}
          <ButtonPrimary
          // href={`/pay-done/${checkin}/${checkout}/${guests}/${roomid}/${id}`}
          // onClick={submitReserva}
          >
            Reservar
          </ButtonPrimary>
        </div>
      </form>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirmación del Producto
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <h3 className="text-2xl font-semibold">Dejanos tus datos</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          {formtype === "packages" ? renderPackagesForm() : ""}
          {formtype === "accomodation-reservation" || !formtype
            ? renderAccomodationForm()
            : ""}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutForm ${className}`} data-nc-id="CheckOutForm">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutForm;
