import React, { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
} from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import MobileFooterSticky from "./MobileFooterSticky";
import { getContentFromContentful } from "utils/contentful/contentful";
import { AccomodationInterface } from "domain/accomodation-item";
import { Entry } from "contentful";
import { useParams } from "react-router-dom";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import FormItem from "containers/PageAddListing1/FormItem";
import Select from "shared/Select/Select";
import { ContentfulRooms } from "domain/room-item";
import { useGlobalAppDispatch, useGlobalAppState } from "store/app-context";
import { AppActions } from "store/reducer";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

export const iconFinder = (amenity: String) => {
  switch (amenity.toLocaleLowerCase().replaceAll(" ", "")) {
    case "tv":
      return "la-tv";
      break;
    case "wifi":
      return "la-wifi";
      break;
    case "wifienáreaspúblicasdelhotel":
      return "la-wifi";
      break;
    case "estacionamiento":
      return "la-parking";
      break;
    case "desayunoincluido":
      return "la-bread-slice";
      break;
    case "desayuno":
      return "la-bread-slice";
      break;
    case "desayunobuffet":
      return "la-bread-slice";
      break;
    case "petfriendly":
      return "la-dog";
      break;
    case "restaurante":
      return "la-utensils";
      break;
    case "businesscenter":
      return "la-business-time";
      break;
    case "bar":
      return "la-beer";
      break;
    case "aireacondicionado":
      return "la-thermometer";
      break;
    case "calefacción":
      return "la-fire";
      break;
    case "mesadetrabajo":
      return "la-chair";
      break;
    case "bañoprivado":
      return "la-restroom";
      break;
    case "cafetería":
      return "la-coffee";
      break;
    case "televisión":
      return "la-tv";
      break;
    case "roomservice":
      return "la-person-booth";
      break;
    case "cajadeseguridad":
      return "la-box";
      break;
    case "piscina":
      return "la-swimming-pool";
      break;
    default:
      return "la-star";
      break;
  }
};

export const renderTienIch = (amenities: string[]) => {
  return (
    <div className="hidden sm:grid grid-cols-3 gap-2">
      {amenities.slice(0, 6).map((amenity) => (
        <div className="space-y-3" key={amenity}>
          <div className="flex items-center space-x-3 ">
            <i className={`las ${iconFinder(amenity)} text-2xl`}></i>
            <span className="">
              <span className="hidden sm:inline-block">{amenity}</span>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  let { checkin, checkout, guests, id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedRoomId, setSelectedRoomId] = useState<String>();
  const [selectedRoomObj, setSelectedRoomObj] =
    useState<ContentfulRooms | null>();
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment(checkin),
    endDate: moment(checkout),
  });
  const [accomodation, setAccomodation] =
    useState<Entry<AccomodationInterface>>();
  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 0,
    guestInfants: 0,
  });

  const windowSize = useWindowSize();

  const dispatch = useGlobalAppDispatch();
  const { selectedAccomodation } = useGlobalAppState();

  useEffect(() => {
    setGuestInput({
      guestAdults: +(guests ?? 2),
      guestChildren: 0,
      guestInfants: 0,
    });
    if (selectedAccomodation?.fields) {
      setAccomodation(selectedAccomodation);
      setSelectedRoomObj(
        selectedAccomodation?.fields.rooms
          ? selectedAccomodation?.fields.rooms[0]
          : null
      );
      setSelectedRoomId(
        selectedAccomodation?.fields.rooms
          ? selectedAccomodation?.fields.rooms[0].sys.id
          : ""
      );
      return;
    }

    if (id) {
      getContentFromContentful<AccomodationInterface>(id).then((content) => {
        setSelectedRoomObj(
          content?.fields.rooms ? content?.fields.rooms[0] : null
        );
        setSelectedRoomId(
          content?.fields.rooms ? content?.fields.rooms[0].sys.id : ""
        );
        setAccomodation(content);
        dispatch({
          type: AppActions.GetMyAddContent,
          payload: content,
        });
      });
    }
  }, []);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={accomodation?.fields.accomodationType} />
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {accomodation && accomodation.fields.name}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating
            point={accomodation?.fields.accomodationStars}
            reviewCount={-1}
          />
        </div>

        {/* 4 */}
        {/* <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          {accomodation?.fields.amenities &&
            renderTienIch(accomodation?.fields.amenities)}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Información del Hospedaje</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div
          className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
          dangerouslySetInnerHTML={
            accomodation?.fields.description
              ? {
                  __html: documentToHtmlString(
                    accomodation?.fields.description
                  ),
                }
              : {
                  __html: "",
                }
          }
        >
          {/* <span>
            Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
            accommodation, an outdoor swimming pool, a bar, a shared lounge, a
            garden and barbecue facilities. Complimentary WiFi is provided.
          </span>
          <br />
          <br />
          <span>
            There is a private bathroom with bidet in all units, along with a
            hairdryer and free toiletries.
          </span>
          <br /> <br />
          <span>
            The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental
            service and a car rental service are available at the accommodation,
            while cycling can be enjoyed nearby.
          </span> */}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Comodidades </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Principales comodidades del alojamiento
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {accomodation?.fields.amenities &&
            accomodation.fields.amenities
              .filter((_, i) => i < 12)
              .map((item) => (
                <div key={item} className="flex items-center space-x-3">
                  <i className={`text-3xl las ${iconFinder(item)}`}></i>
                  <span className=" ">{item}</span>
                </div>
              ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            Ver todas las comodidades
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Comodidades
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {accomodation?.fields.amenities &&
                      accomodation.fields.amenities
                        .filter((_, i) => i < 1212)
                        .map((item) => (
                          <div
                            key={item}
                            className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                          >
                            <i
                              className={`text-4xl text-neutral-6000 las ${iconFinder(
                                item
                              )}`}
                            ></i>
                            <span>{item}</span>
                          </div>
                        ))}
                    {/* {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))} */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Información del Hotel</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">See host profile</ButtonSecondary>
        </div> */}
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Dirección</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {/* San Diego, CA, United States of America (SAN-San Diego Intl.) */}
            {`${accomodation?.fields.address}, Lima, Perú`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            {accomodation && (
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyB-HGyVp7lHxrwQBdaEXdsbFXwX4dJUirc",
                }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={15}
                defaultCenter={{
                  lat: accomodation.fields.coordenates.lat,
                  lng: accomodation.fields.coordenates.lon,
                }}
              >
                <LocationMarker
                  lat={accomodation.fields.coordenates.lat}
                  lng={accomodation.fields.coordenates.lon}
                />
              </GoogleMapReact>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const requestRegion = (event: any) => {
    setSelectedRoomId(event.target.value);
    setSelectedRoomObj(
      accomodation?.fields.rooms?.filter(
        (f) => f.sys.id === event.target.value
      )[0]
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {`$${
              selectedRoomObj
                ? selectedRoomObj.fields.nightPrice
                : accomodation?.fields.nightPriceAvg
            }`}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /noche
            </span>
          </span>
          <StartRating
            point={accomodation?.fields.accomodationStars}
            reviewCount={-1}
          />
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
            onChange={(date) => setSelectedDate(date)}
            fieldClassName="p-3"
            defaultValue={selectedDate}
            anchorDirection={"right"}
            className="nc-ListingStayDetailPage__stayDatesRangeInput flex-1"
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            className="nc-ListingStayDetailPage__guestsInput flex-1"
            fieldClassName="p-3"
            defaultValue={guestInput}
            onChange={setGuestInput}
            hasButtonSubmit={false}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          {accomodation && (
            <FormItem label="Habitación">
              <Select onChange={requestRegion}>
                {accomodation.fields.rooms?.map((item) => (
                  <option
                    key={item.sys.id}
                    value={item.sys.id}
                  >{`${item.fields.roomName} - $${item.fields.nightPrice}`}</option>
                ))}
              </Select>
            </FormItem>
          )}
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{`$${
              selectedRoomObj
                ? selectedRoomObj.fields.nightPrice
                : accomodation?.fields.nightPriceAvg
            } x ${selectedDate.endDate?.diff(
              selectedDate.startDate,
              "days"
            )} noches`}</span>
            <span>{`$${
              (selectedRoomObj?.fields?.nightPrice
                ? selectedRoomObj.fields.nightPrice
                : accomodation?.fields.nightPriceAvg ?? 0) *
              (selectedDate.endDate?.diff(selectedDate?.startDate, "days") ?? 3)
            } `}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Subtotal</span>
            <span>$0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{`$${
              (selectedRoomObj?.fields?.nightPrice
                ? selectedRoomObj.fields.nightPrice
                : accomodation?.fields.nightPriceAvg ?? 0) *
              (selectedDate.endDate?.diff(selectedDate?.startDate, "days") ?? 3)
            }`}</span>
          </div>
        </div>

        {/* SUBMIT */}
        {/* <ButtonPrimary href={"/checkout"}>Reservar</ButtonPrimary> */}
        <ButtonPrimary
          href={`/checkout/accomodation-reservation/${selectedDate.startDate?.format(
            "YYYY-MM-DD"
          )}/${selectedDate.endDate?.format("YYYY-MM-DD")}/${
            guestInput.guestAdults
          }/${selectedRoomId}/${id}`}
        >
          Reservar
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              {accomodation?.fields.images && (
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  // src={PHOTOS[0]}
                  src={`${accomodation?.fields.images[0].fields.file.url}`}
                />
              )}
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {accomodation?.fields.images &&
              accomodation?.fields.images
                .filter((_, i) => i >= 1 && i < 5)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 3 ? "hidden sm:block" : ""
                    }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={item.fields.file.url || ""}
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Ver Imagenes
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        {accomodation?.fields.images && (
          <ModalPhotos
            imgs={accomodation?.fields.images?.map((im) => im.fields.file.url)}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
          />
        )}
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {/* {renderSection4()} */}
          {/* {renderSectionCheckIndate()} */}
          {/* {renderSection5()} */}
          {/* {renderSection6()} */}
          {renderSection7()}
          {/* {renderSection8()} */}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && (
        <MobileFooterSticky
          guests={guestInput}
          selectedDatesInp={selectedDate}
          totalPrice={
            selectedRoomObj?.fields?.nightPrice
              ? selectedRoomObj.fields.nightPrice
              : accomodation?.fields.nightPriceAvg ?? 0
          }
        />
      )}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}
          <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explora algunos Hoteles"
              subHeading="Hoteles destacados en Lima, Perú"
              categoryCardType="card4"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName={"ListingStayDetailPage1"}
            />
          </div>

          {/* SECTION */}
          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
        </div>
      )}
    </div>
  );
};

export default ListingStayDetailPage;
