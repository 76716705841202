import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
// import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link, useParams } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { Entry } from "contentful";
import { AccomodationInterface } from "domain/accomodation-item";

export interface StayCardHProps {
  className?: string;
  data?: Entry<AccomodationInterface>;
}

// const DEMO_DATA = DEMO_STAY_LISTINGS[0];
export const renderTienIch = (amenities: string[]) => {
  const iconFinder = (amenity: String) => {
    switch (amenity.toLocaleLowerCase().replaceAll(" ", "")) {
      case "tv":
        return "la-tv";
        break;
      case "wifi":
        return "la-wifi";
        break;
      case "wifienáreaspúblicasdelhotel":
        return "la-wifi";
        break;
      case "estacionamiento":
        return "la-parking";
        break;
      case "desayunoincluido":
        return "la-bread-slice";
        break;
      case "desayuno":
        return "la-bread-slice";
        break;
      case "desayunobuffet":
        return "la-bread-slice";
        break;
      case "petfriendly":
        return "la-dog";
        break;
      case "restaurante":
        return "la-utensils";
        break;
      case "businesscenter":
        return "la-business-time";
        break;
      case "bar":
        return "la-beer";
        break;
      case "aireacondicionado":
        return "la-thermometer";
        break;
      case "calefacción":
        return "la-fire";
        break;
      case "mesadetrabajo":
        return "la-chair";
        break;
      case "bañoprivado":
        return "la-restroom";
        break;
      case "cafetería":
        return "la-coffee";
        break;
      case "televisión":
        return "la-tv";
        break;
      case "roomservice":
        return "la-person-booth";
        break;
      case "cajadeseguridad":
        return "la-box";
        break;
      case "piscina":
        return "la-swimming-pool";
        break;
      default:
        return "la-star";
        break;
    }
  };

  return (
    <div className="hidden sm:grid grid-cols-3 gap-2">
      <div className="space-y-3">
        {amenities.slice(0, 2).map((amenity) => (
          <div className="flex items-center space-x-3" key={amenity}>
            <i className={`las ${iconFinder(amenity)}  text-lg`}></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {amenity}
            </span>
          </div>
        ))}
      </div>
      <div className="space-y-3">
        {amenities.slice(2, 4).map((amenity) => (
          <div className="flex items-center space-x-3" key={amenity}>
            <i className={`las ${iconFinder(amenity)}  text-lg`}></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {amenity}
            </span>
          </div>
        ))}
      </div>
      <div className="space-y-3">
        {amenities.slice(4, 6).map((amenity) => (
          <div className="flex items-center space-x-3" key={amenity}>
            <i className={`las ${iconFinder(amenity)}  text-lg`}></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {amenity}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const StayCardH: FC<StayCardHProps> = ({ className = "", data }) => {
  let { location, checkin, checkout, guests } = useParams();
  // const {
  // galleryImgs,
  // listingCategory,
  // address,
  // title,
  // href,
  // like,
  // saleOff,
  // isAds,
  // price,
  // reviewStart,
  // reviewCount,
  // id,
  // } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          contentfulImgs={data?.fields.images}
          uniqueID={`StayCardH_${data?.fields?.id}`}
          // href={`/listing-stay-detail/${data?.sys.id}`}
          href={`/listing-stay-detail/${checkin ?? "2023-06-15"}/${
            checkout ?? "2023-06-18"
          }/${guests}/${data?.sys.id}`}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" /> */}
        {data?.fields?.discountMessage && (
          <SaleOffBadge
            desc={data?.fields?.discountMessage}
            className="absolute left-3 top-3"
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>{data?.fields?.address}</span>
          </div>
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{data?.fields?.name}</span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {data?.fields?.amenities && renderTienIch(data?.fields?.amenities)}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
          <span className="text-base font-semibold text-secondary-500">
            {`$${data?.fields?.nightPriceAvg}`}
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /noche
            </span>
          </span>
        </div>
      </div>
    );
  };
  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link
        // to={`/listing-stay-map/${data?.sys.id}`}
        to={`/listing-stay-detail/${checkin ?? "2023-06-15"}/${
          checkout ?? "2023-06-18"
        }/${guests}/${data?.sys.id}`}
        className="absolute inset-0"
      ></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
