import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Heading2 from "components/Heading/Heading2";
import Label from "components/Label/Label";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFilterCard from "containers/ListingStayPage/SectionGridFilterCard";
import { Entry } from "contentful";
import { StayDataType } from "data/types";
import { AccomodationInterface } from "domain/accomodation-item";
import { ReservationInterface } from "domain/contentful/reservation-item";
import { PackagesIntereface } from "domain/packages-item";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import { getContentTypeFromContentful } from "utils/contentful/contentful";
import { createPurchaseOrderInContentful } from "utils/contentful/contentful-management";

export interface TransportPackagesProps {
  className?: string;
}

const priceArray = [
  {
    district: "Miraflores",
    service: "Servicio-Privado-1-Pax",
    value: 35,
  },
  {
    district: "Miraflores",
    service: "Servicio-Privado-2-Paxs",
    value: 45,
  },
  {
    district: "Miraflores",
    service: "Servicio-Privado-Hasta-7-Paxs",
    value: 150,
  },
  {
    district: "Miraflores",
    service: "Servicio-Compartido-Pool",
    value: 18,
  },
  {
    district: "San-Isidro",
    service: "Servicio-Privado-1-Pax",
    value: 35,
  },
  {
    district: "San-Isidro",
    service: "Servicio-Privado-2-Paxs",
    value: 45,
  },
  {
    district: "San-Isidro",
    service: "Servicio-Privado-Hasta-7-Paxs",
    value: 150,
  },
  {
    district: "San-Isidro",
    service: "Servicio-Compartido-Pool",
    value: 18,
  },
  {
    district: "San-Borja",
    service: "Servicio-Privado-1-Pax",
    value: 38,
  },
  {
    district: "San-Borja",
    service: "Servicio-Privado-2-Paxs",
    value: 50,
  },
  {
    district: "San-Borja",
    service: "Servicio-Privado-Hasta-7-Paxs",
    value: 160,
  },
  {
    district: "San-Borja",
    service: "Servicio-Compartido-Pool",
    value: 20,
  },
  {
    district: "San-Miguel-Magdalena",
    service: "Servicio-Privado-1-Pax",
    value: 35,
  },
  {
    district: "San-Miguel-Magdalena",
    service: "Servicio-Privado-2-Paxs",
    value: 45,
  },
  {
    district: "San-Miguel-Magdalena",
    service: "Servicio-Privado-Hasta-7-Paxs",
    value: 150,
  },
  {
    district: "San-Miguel-Magdalena",
    service: "Servicio-Compartido-Pool",
    value: 18,
  },
];

const TransportPackages: FC<TransportPackagesProps> = ({ className = "" }) => {
  const [form, setForm] = useState<ReservationInterface>(
    {} as ReservationInterface
  );
  let navigate = useNavigate();
  const [selectorRuta, setSelectorRuta] = useState<string>("Aeropuerto–Hotel");
  const [selectorServicio, setSelectorServicio] = useState<string>(
    "Servicio-Privado-1-Pax"
  );
  const [selectorDistrito, setSelectorDistrito] =
    useState<string>("Miraflores");

  const subHeading = () => {
    return (
      <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
        ¿Necesitas transporte del o hacia el aeropuerto internacional Jorge
        Chavez?
        <br></br>
        Reserva aquí tu traslado
      </span>
    );
  };

  const formTypeRender = (item: any) => {
    switch (item) {
      case "arrival":
        return "Transporte de Llegada";
        break;
      case "departure":
        return "Transporte de Salida";
        break;
      default:
        return item;
        break;
    }
  };

  const submitReserva = () => {
    createPurchaseOrderInContentful({
      reservation: {
        ...form,
        name: selectorRuta,
        typeOfreservation: "Transporte",
        District: selectorDistrito,
        transportServiceType: selectorServicio,
        price: priceArray
          .filter(
            (pr) =>
              pr.district == selectorDistrito && pr.service == selectorServicio
          )[0]
          ?.value.toString(),
      },
    });
    navigate(`/pay-done`);
  };

  const renderAccomodationDepartureForm = () => {
    return (
      <div className="w-full relative rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
        <h4 className="text-xl font-semibold">Traslado de Salida</h4>
        <div className="mt-6">
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>Fecha</Label>
              <Input
                type="date"
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, arrivalDate: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Hora de Recojo</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, arrivalHotelTime: e.currentTarget.value })
                }
              />
            </div>
          </div>
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>Hotel</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, hotelAirport: e.currentTarget.value })
                }
              />
            </div>
            {/* <div className="flex-1 space-y-1">
              <Label>Distrito</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, District: e.currentTarget.value })
                }
              />
            </div> */}
          </div>
          <div className="space-y-1">
            <Label>Dirección</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, address: e.currentTarget.value })
              }
            />
          </div>
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>Nombres</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, firstName: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Apellidos</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, lastName: e.currentTarget.value })
                }
              />
            </div>
          </div>
          <div className="space-y-1">
            <Label>Email</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) => {
                setForm({ ...form, email: e.currentTarget.value });
              }}
            />
          </div>
        </div>
        <br />
        <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
        <br />

        <div className="pt-8">
          {/* <ButtonPrimary href={"/pay-done"}>Reservar</ButtonPrimary> */}
          {/* <ButtonPrimary type="submit" href={`/pay-done`}>
            Reservar
          </ButtonPrimary> */}
        </div>
      </div>
    );
  };
  const renderAccomodationArrivalForm = () => {
    return (
      <div className="">
        <h4 className="text-xl font-semibold">Traslado de Llegada</h4>
        <div className="mt-6">
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>Fecha</Label>
              <Input
                type="date"
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, departureDate: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Hora de Llegada</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, arrivalHotelTime: e.currentTarget.value })
                }
              />
            </div>
          </div>
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>No de Vuelo</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, flightNumber: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Aerolínea</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, airline: e.currentTarget.value })
                }
              />
            </div>
          </div>
          <div className="space-y-1">
            <Label>Hotel</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, hotelAirport: e.currentTarget.value })
              }
            />
          </div>
          {/* <div className="space-y-1">
            <Label>Distrito</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, District: e.currentTarget.value })
              }
            />
          </div> */}
          <div className="space-y-1">
            <Label>Dirección</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) =>
                setForm({ ...form, address: e.currentTarget.value })
              }
            />
          </div>
          <div className="flex space-x-5  ">
            <div className="flex-1 space-y-1">
              <Label>Nombres</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, firstName: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Apellidos</Label>
              <Input
                defaultValue=""
                required={true}
                onChange={(e) =>
                  setForm({ ...form, lastName: e.currentTarget.value })
                }
              />
            </div>
          </div>

          <div className="space-y-1">
            <Label>Email</Label>
            <Input
              defaultValue=""
              required={true}
              onChange={(e) => {
                setForm({ ...form, email: e.currentTarget.value });
              }}
            />
          </div>
        </div>
        <br />
        <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
        <br />

        <div className="pt-8">
          {/* <ButtonPrimary href={"/pay-done"}>Reservar</ButtonPrimary> */}
          {/* <ButtonPrimary type="submit" href={`/pay-done`}>
            Reservar
          </ButtonPrimary> */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-TransportPackages relative overflow-hidden ${className}`}
      data-nc-id="TransportPackages"
    >
      <Helmet>
        <title>Bestway || Traslado </title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        <Heading2 heading={"Transporte"} subHeading={subHeading()} />

        <form
          onSubmit={submitReserva}
          className="w-full relative rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800"
        >
          <div className="mt-6">
            <div className="flex space-x-5  ">
              <div className="flex-1 space-y-1">
                <div className="flex-1 space-y-1">
                  <h4 className="text-xl font-semibold">Tipo de Traslado</h4>

                  <Select
                    className="mt-1.5"
                    onChange={(e) => {
                      setSelectorRuta(e.currentTarget.value);
                    }}
                  >
                    <option value="Aeropuerto–Hotel">Aeropuerto – Hotel</option>
                    <option value="Hotel-Aeropuerto">Hotel - Aeropuerto</option>
                  </Select>

                  <br />
                  {selectorRuta && (
                    <>
                      <h4 className="text-xl font-semibold">
                        Tipo de Servicio
                      </h4>
                      <Select
                        className="mt-1.5"
                        onChange={(e) => {
                          setSelectorServicio(e.currentTarget.value);
                        }}
                      >
                        <option value="Servicio-Privado-1-Pax">
                          Servicio Privado 1 Pax
                        </option>
                        <option value="Servicio-Privado-2-Paxs">
                          Servicio Privado 2 Paxs
                        </option>
                        <option value="Servicio-Privado-Hasta-7-Paxs">
                          Servicio Privado Hasta 7 Paxs
                        </option>
                        <option value="Servicio-Compartido-Pool">
                          Servicio Compartido (Pool)
                        </option>
                      </Select>
                    </>
                  )}

                  <br />
                  {selectorServicio && (
                    <>
                      <h4 className="text-xl font-semibold">Distrito</h4>
                      <Select
                        className="mt-1.5"
                        onChange={(e) => {
                          setSelectorDistrito(e.currentTarget.value);
                        }}
                      >
                        <option value="Miraflores">Miraflores</option>
                        <option value="San-Isidro">San Isidro</option>
                        <option value="San-Borja">San Borja</option>
                        <option value="San-Miguel-Magdalena">
                          San Miguel / Magdalena
                        </option>
                      </Select>
                    </>
                  )}

                  <br />
                  {selectorRuta && selectorDistrito && selectorServicio && (
                    <>
                      <h4 className="text-xl font-semibold">Precio</h4>
                      <span>
                        ${" "}
                        {
                          priceArray.filter(
                            (pr) =>
                              pr.district == selectorDistrito &&
                              pr.service == selectorServicio
                          )[0]?.value
                        }
                        .00
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <div className="relative overflow-hidden">
            {selectorDistrito &&
              selectorRuta === "Aeropuerto–Hotel" &&
              renderAccomodationArrivalForm()}{" "}
            {selectorDistrito &&
              selectorRuta === "Hotel-Aeropuerto" &&
              renderAccomodationDepartureForm()}{" "}
          </div>
          <ButtonPrimary
            onClick={() => submitReserva()}
            type="submit"
            href={`/pay-done`}
          >
            Reservar
          </ButtonPrimary>
        </form>
      </div>
    </div>
  );
};

export default TransportPackages;
