import { Helmet } from "react-helmet";
import santiagodesurco from "images/santiagodesurco.jpg";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import GoogleMapReact from "google-map-react";
import { AccomodationInterface } from "domain/accomodation-item";
import { Entry } from "contentful";
import { useEffect, useState } from "react";
import { getContentTypeFromContentful } from "utils/contentful/contentful";
import { useGlobalAppDispatch, useGlobalAppState } from "store/app-context";
import { AppActions } from "store/reducer";
import Checkbox from "shared/Checkbox/Checkbox";
import AnyReactComponentNoPrice from "components/AnyReactComponent/AnyReactComponentNoPrice";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import NcImage from "shared/NcImage/NcImage";
import rightImgPng from "images/our-features.jpg";

const BlogMapsOfLima = () => {
  const [accomodations, setAccomodations] = useState<
    Entry<AccomodationInterface>[]
  >([]);

  const dispatch = useGlobalAppDispatch();
  const { accomodationList } = useGlobalAppState();
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);

  useEffect(() => {
    if (accomodationList?.length > 0) {
      setAccomodations(accomodationList);
    }

    getContentTypeFromContentful<any>("Accomodation").then((content) => {
      setAccomodations(content.filter((f) => !f.fields.hideAccomodation));
      dispatch({
        type: AppActions.GetMyListAccomodations,
        payload: content,
      });
    });
  }, []);

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          {/* <Badge href="##" color="purple" name="Traveler" /> */}
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Mapa de Lima
          </h1>

          <div className="" style={{ height: "30rem" }}>
            <GoogleMapReact
              defaultZoom={12}
              defaultCenter={{
                lat: -12.090678,
                lng: -77.089558,
              }}
              bootstrapURLKeys={{
                key: "AIzaSyB-HGyVp7lHxrwQBdaEXdsbFXwX4dJUirc",
              }}
              yesIWantToUseGoogleMapApiInternals
            >
              <LocationMarker lat={-12.090695} lng={-77.089624} />
              {accomodations &&
                accomodations.map((item) => {
                  if (!item.fields.hideAccomodation) {
                    return (
                      <AnyReactComponentNoPrice
                        isSelected={currentHoverID === item.sys.id}
                        key={item.sys.id}
                        lat={item.fields.coordenates?.lat}
                        lng={item.fields.coordenates?.lon}
                        listing={item}
                      />
                    );
                  }
                })}
            </GoogleMapReact>
          </div>
          <div style={{ display: "flex", placeContent: "center" }}>
            {/* <NcImage
              src={rightImgPng}
              style={{ height: "3rem", width: "auto" }}
            /> */}
            <div className="text-primary-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-11 w-11"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <span style={{ alignSelf: "center", margin: "0.5rem" }}>
              Evento Extravaganza
            </span>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <br></br>
        <h2>Distritos de Lima</h2>
        <figure>
          <img
            src="https://thumbs.dreamstime.com/b/vista-a%C3%A9rea-del-acantilado-de-la-ciudad-miraflores-y-carretera-costa-verde-en-lima-peru-259466537.jpg"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>
        <h3>Miraflores</h3>
        <p>
          El distrito de Miraflores es reconocida como una ciudad turística por
          excelencia. Su historia se remota desde los vestigios más antiguos
          encontrados en la zona arqueológica de la Huaca Pucllana, antiguo
          centro ceremonial de la Cultura Lima (200 a 700 d.C.), hasta la
          modernidad.
        </p>
        <p>
          En sus líneas arquitectónicas se une el encanto solariego de antiguas
          mansiones y quintas que hablan del esplendor de su pasado. También
          cuenta con modernos centros comerciales, clubes y restaurantes que
          ofrecen especialidades de la cocina nacional e internacional.
        </p>
        <p>
          Bajo un mismo cielo, Miraflores reune hoteles de primera categoría,
          tiendas artesanales, galerías de arte, teatros y centros
          recreacionales de diversa índole. Incluso se ha organizado y ordenado
          el comercio ambulatorio, que exhibe su mercadería en atractivos
          cochecitos que son la atracción de los visitantes.
        </p>
        <p>
          Para admirar el litoral miraflorino, se tienen malecones y miradores
          naturales desde los que se aprecian hermosas puestas de sol y la
          majestuosidad e inmensidad del mar.
        </p>
        <p>
          Miraflores se distingue también por su vida nocturna pues abren sus
          puertas al caer el sol, locales que ofrecen diversas variedades de
          espectáculos, tales como peñas, discotecas, teatros, cines, bares,
          entro otros.
        </p>
        <p>
          Una importante actividad cultural también se manifiesta en lugares
          públicos. En el corazón del distrito, puede encontrarse el Anfiteatro
          Chabuca Granda del Parque Central y muy cerca la exhibición y venta de
          artesanías elaborados por los mejores exponentes de todo el país en la
          rotonda del Parque Kennedy.
        </p>
        <p>
          A menudo los parques de Miraflores son escenario de diversos
          espectáculos y actividades al aire libre. Incluso, todos los años
          nuestro parque central alberga la concurrida Feria del Libro “Ricardo
          Palma” durante el mes de noviembre.
        </p>

        <br></br>
        <h3>San Miguel</h3>
        <figure>
          <img
            src={
              "https://imageio.forbes.com/specials-images/imageserve/60a7d09c2dd595b229dc2f66/0x0.jpg?format=jpg&width=1200"
            }
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>
        <p>
          El distrito de San Miguel se encuentra localizado dentro de la
          provincia y departamento de Lima. Su ubicación, al oeste del centro de
          Lima, lo convierte en un nexo entre los distritos del sur de la ciudad
          con el terminal marítimo del Callao y el Aeropuerto Internacional
          Jorge Chávez.
        </p>
        <p>
          San Miguel es un distrito que cuenta con diversas zonas turísticas,
          tanto para la promoción del arte, el deporte, la historia y la
          religión.
        </p>
        <p>
          Su nombre deriva gracias a unos de los arcángel: San Miguel y hoy en
          día podemos encontrar un monumento de él en las intermediaciones de la
          avenida la Marina con Elmer Facucett.
        </p>
        <p>
          Entre los atractivos turísticos de San Miguel se puede mencionar su
          gran legado de Huaca, el Museo George Petersen de Ingeniería de Minas,
          la Casa de la Cultura y lugares de esparcimiento como lo el Zoologico
          Parque de las Leyendas.
        </p>

        <br></br>
        <h3>San Borja</h3>
        <figure>
          <img
            src="https://blog.nexoinmobiliario.pe/wp-content/uploads/2021/04/san-borja-mejores-lugares-vivir-lima.jpg"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>
        <p>
          San Borja es un distrito de carácter residencial, con iniciativa para
          las actividades sociales, deportivas, comerciales y culturales al ser
          sede del complejo denominado Centro Cultural de la Nación pues aquí se
          encuentra la Biblioteca nacional del Perú, el Gran Teatro Nacional del
          Perú y el Museo de la Nación del Perú.
        </p>
        <p>
          Es muy atractivo por su carácter residencial y sus extensas áreas
          verdes que totalizan 1 347 000 m² y lo convierten en uno de los
          distritos de Lima con mayor superficie verde. Con una cantidad de
          11.86 m² por habitante, es también uno de los distritos limeños con
          mayor densidad de parques y jardines.
        </p>
        <p>San Borja posee tres importantes lugares turísticos:</p>
        <ul>
          <li>
            El Centro Cultural de la Nación, que es un complejo que alberga las
            principales instituciones culturales del país incluyendo el Gran
            Teatro Nacional del Perú, la Biblioteca Nacional del Perú y el Museo
            de la Nación, notable ejemplo (junto al complejo de "El
            Pentagonito") de la corriente arquitectónica de la época conocida
            como brutalismo.
          </li>
          <li>La huaca San Borja.</li>
          <li>El complejo arqueológico Limatambo.</li>
        </ul>
        <p>
          Se cuenta además con dos grandes centros comerciales que son La Rambla
          en la av. Javier Prado Este y Real Plaza Primavera en la av. Angamos
          Este, en el límite el distrito de Surquillo. Ambos cuentan con
          numerosas tiendas por departamentos, supermercados, restaurantes,
          salas de cine y lugares de diversión.
        </p>
        <p>
          El templo principal del distrito es la parroquia San Francisco de
          Borja, siendo también importantes las parroquias Santísimo Nombre de
          Jesús, Nuestra Señora de Gracia, San Leopoldo y de Nuestra Señora de
          la Alegría.
        </p>

        <br></br>
        <h3>San Isidro</h3>
        <figure>
          <img
            src="https://ammgrupoinmobiliario.com/wp-content/uploads/2021/03/viviren-san-isidro.jpg"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>
        <p>
          San Isidro es el distrito financiero de Lima, en el cual alberga las
          sedes centrales de los mayores bancos del país.
        </p>
        <p>
          Es también conocido como el distrito "jardín" de Lima por su enorme
          cantidad de áreas verdes. El Bosque El Olivar es uno de esos
          atractivos, un hermoso bosque de la época colonial con varias
          hectáreas sembradas de olivos hace más de cuatro siglos, algunos de
          los cuales fueron sembrados por San Martín de Porres y fue declarado
          Monumento Nacional en 1959. Actualmente, El Olivar se convirtió en un
          parque tranquilo e ideal para un picnic al aire libre, y un gran
          pulmón para la ciudad.
        </p>
        <p>
          En él se encuentra la sede la Biblioteca Municipal de San Isidro (la
          más completa y con mayor actividad cultural de las bibliotecas
          municipales de Lima) y la laguna, además de encontrarse una de las
          zonas residenciales del distrito que se mantienen en la actualidad.
        </p>
        <p>
          Este distrito es uno de los preferidos por los turistas que llegan a
          la ciudad de Lima ya que hay numerosos y excelentes restaurantes,
          centros comerciales, galerías de arte, cafés, casinos, salas de
          teatros y cines, etc.
        </p>
        <p>
          Otros atractivos turísticos de San Isidro son la Fundación
          Biblioteca-Museo Temple Radicati (Universidad Nacional Mayor de San
          Marcos), la Huaca Huallamarca y museo de sitio Huaca Santa Cruz y el
          Museo Marina Núñez del Prado.
        </p>
        <p>
          San Isidro posee una gran actividad cultural, con varios teatros,
          galerías de arte, librerías y una casa de la cultura. Se llevan
          también a cabo diversos programas municipales y eventos culturales que
          dan uso a los espacios públicos.
        </p>
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Bestway || Información Útil</title>
      </Helmet>
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  );
};

export default BlogMapsOfLima;
