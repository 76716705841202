import React, { FC, useEffect, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import {
  getContentFromContentful,
  getContentTypeFromContentful,
  getContentTypeLimaFromContentful,
} from "utils/contentful/contentful";
import { AccomodationInterface } from "domain/accomodation-item";
import { Entry } from "contentful";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useGlobalAppDispatch, useGlobalAppState } from "store/app-context";
import { AppActions } from "store/reducer";
import LocationMarker from "components/AnyReactComponent/LocationMarker";

// const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

const typeOfPaces = [
  {
    name: "San Borja",
    description: "Distrito de carácter residencial",
    isChecked: true,
  },
  {
    name: "San Miguel",
    description: "Distrito histórico, cultural y turístico",
    isChecked: true,
  },
  {
    name: "Miraflores",
    description: "Distrito turística por excelencia",
    isChecked: true,
  },
  {
    name: "San Isidro",
    description: "El distrito financiero de Lima",
    isChecked: true,
  },
];

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [accomodations, setAccomodations] = useState<
    Entry<AccomodationInterface>[]
  >([]);
  const { location, checkin, checkout, guests } = useParams();

  const dispatch = useGlobalAppDispatch();
  const { accomodationList } = useGlobalAppState();

  const [districtFilter, setDistrictFilter] = useState(typeOfPaces);

  useEffect(() => {
    if (accomodationList?.length > 0) {
      setAccomodations(accomodationList);
    }

    getContentTypeLimaFromContentful<any>("Accomodation").then((content) => {
      // setAccomodations(content.filter((f) => !f.fields.hideAccomodation));
      setAccomodations(content);
      dispatch({
        type: AppActions.GetMyListAccomodations,
        payload: content,
      });
    });
  }, []);

  const subheading = () => {
    return (
      <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
        {accomodations.length + 1} Hoteles
        <span className="mx-2">·</span>
        {moment(checkin).locale("es").format("MMM D")} -{" "}
        {moment(checkout).format("D")}
      </span>
    );
  };

  const handleSubmitDistrictFilter = () => {};

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2 subHeading={subheading()} />
          <div className="mb-8 lg:mb-11">
            <TabFilters
              handleSubmitDistrictFilter={handleSubmitDistrictFilter}
              districtFilter={districtFilter}
              setDistrictFilter={setDistrictFilter}
            />
          </div>
          <div className="grid grid-cols-1 gap-8">
            {accomodations &&
              accomodations.map((item) => {
                if (
                  districtFilter.filter(
                    (f) =>
                      f.name.toUpperCase() ===
                      item?.fields?.district?.toUpperCase()
                  )[0]?.isChecked
                ) {
                  return (
                    <div
                      key={item.sys.id}
                      onMouseEnter={() => setCurrentHoverID((_) => item.sys.id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <StayCardH data={item} />
                    </div>
                  );
                }
              })}
            {/* {DEMO_STAYS &&
              DEMO_STAYS.map((item) => (
                <div
                  key={item.id}
                  onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                  onMouseLeave={() => setCurrentHoverID((_) => -1)}
                >
                  <StayCardH data={item} />
                </div>
              ))} */}
          </div>
          {/* <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div> */}
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              />
            </div>

            {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}

            <GoogleMapReact
              defaultZoom={12}
              defaultCenter={{
                lat: -12.090678,
                lng: -77.089558,
              }}
              bootstrapURLKeys={{
                key: "AIzaSyB-HGyVp7lHxrwQBdaEXdsbFXwX4dJUirc",
              }}
              yesIWantToUseGoogleMapApiInternals
            >
              <LocationMarker lat={-12.090695} lng={-77.089624} />
              {accomodations &&
                accomodations.map((item) => {
                  if (
                    districtFilter.filter(
                      (f) =>
                        f.name.toUpperCase() ===
                        item?.fields?.district?.toUpperCase()
                    )[0]?.isChecked
                  ) {
                    return (
                      <AnyReactComponent
                        isSelected={currentHoverID === item.sys.id}
                        key={item.sys.id}
                        lat={item.fields.coordenates?.lat}
                        lng={item.fields.coordenates?.lon}
                        listing={item}
                      />
                    );
                  }
                })}
              {/* {DEMO_STAYS.map((item) => (
                <AnyReactComponent
                  isSelected={currentHoverID === item.id}
                  key={item.id}
                  lat={item.map.lat}
                  lng={item.map.lng}
                  listing={item}
                />
              ))} */}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
