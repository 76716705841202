import React, { FC, useEffect, useState } from "react";
import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { PackagesIntereface } from "domain/packages-item";
import { Entry } from "contentful";
import StayCardContentfulPackages from "components/StayCard/StayCardContentfulPackages";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
  contentfulData?: Entry<PackagesIntereface>[];
}

const subHeading = () => {
  return (
    <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
      Paquetes Pre-Evento
      <span className="mx-2">·</span>
      Paquetes en Lima - Paracas - Cusco
      <span className="mx-2">·</span>
      válidos para viajes del 10 al 16 de junio 2023
    </span>
  );
};

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
  contentfulData,
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 heading={"Paquetes solo para ti"} subHeading={subHeading()} />

      <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data && data.map((stay) => <StayCard key={stay.id} data={stay} />)}
        {contentfulData &&
          contentfulData.map((stay) => (
            <StayCardContentfulPackages key={stay.sys.id} data={stay} />
          ))}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
