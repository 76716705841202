import rightImg from "images/about-hero-right.png";
import IACA from "images/IATA.jpg";

import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Best Way || Quienes Somos</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 ¿Quienes Somos?"
          btnText=""
          subHeading="Best Way Perú es una agencia de viajes que inicia operaciones en 1997 con el objetivo de brindar a los pasajeros el mejor servicio posible al momento de realizar un viaje. En nuestros inicios nuestro principal enfoque fue el soporte en la logística para la participación efectiva de las delegaciones en misiones comerciales y en eventos internacionales de gran envergadura. Esta experiencia nos permitió desarrollar la capacidad de manejar grandes grupos de profesionales."
        />

        <div
          className={`nc-SectionHero relative ${className}`}
          data-nc-id="SectionHero"
        >
          <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
            <div className="flex-grow">
              <img className="w-full" src={IACA} alt="" />
            </div>
            <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
              <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                Somos una agencia acreditada por la International Air
                Transportation Association (IATA), el organismo líder de la
                industria de la aviación que representa a 265 aerolíneas y más
                80.000 agentes de viajes en más de 120 países
              </span>
            </div>
          </div>
        </div>

        {/* <SectionFounder /> */}
        <div className="relative py-8">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        <SectionStatistic />

        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
