import React, { FC, useEffect, useMemo, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import useNcId from "hooks/useNcId";
import {
  getContentAdsFromContentful,
  getContentFromContentful,
} from "utils/contentful/contentful";
import { AccomodationInterface } from "domain/accomodation-item";
import { Entry } from "contentful";
import { useParams } from "react-router-dom";
import { useGlobalAppDispatch, useGlobalAppState } from "store/app-context";
import { AppActions } from "store/reducer";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Heading of sections",
  subHeading = "Descriptions for sections",
  className = "",
  itemClassName = "",
  categories,
  itemPerRow = 5,
  categoryCardType = "card3",
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const [chisfisTaxonomi, setChisfisTaxonomi] = useState<TaxonomyType[]>([]);
  let { checkin, checkout, guests } = useParams();
  const dispatch = useGlobalAppDispatch();
  const { myAddContent } = useGlobalAppState();

  useEffect(() => {
    if (myAddContent.length > 0) {
      setChisfisTaxonomi(myAddContent);
      return;
    }

    getContentAdsFromContentful<AccomodationInterface>()
      .then((content) => {
        const convertedContent = content.map((it) => {
          return {
            id: it.sys.id,
            href: `/listing-stay-detail/${checkin ?? "2023-06-15"}/${
              checkout ?? "2023-06-18"
            }/2/${it?.sys.id}`,
            name: it.fields.name,
            taxonomy: "category",
            district: it.fields.district,
            thumbnail: it.fields.images
              ? it.fields.images[0].fields.file.url
              : "",
          } as TaxonomyType;
        });
        setChisfisTaxonomi(convertedContent);
        dispatch({
          type: AppActions.GetMyAddContent,
          payload: convertedContent,
        });
      })
      .catch((err) => console.error("[EV][Err]", err));
  }, []);

  const UNIQUE_CLASS =
    "SectionSliderNewCategories__" + uniqueClassName + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  const renderCard = (item: TaxonomyType, index: number) => {
    switch (categoryCardType) {
      case "card3":
        return <CardCategory3 taxonomy={item} />;
      case "card4":
        return <CardCategory4 taxonomy={item} />;
      case "card5":
        return <CardCategory5 taxonomy={item} />;
      default:
        return <CardCategory3 taxonomy={item} />;
    }
  };

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {chisfisTaxonomi &&
              chisfisTaxonomi.map((item, index) => (
                <li key={index} className={`glide__slide ${itemClassName}`}>
                  {renderCard(item, index)}
                </li>
              ))}
          </ul>
        </div>

        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
