import { ContentfulClientApi, createClient, CreateClientParams, Entry } from 'contentful';

let client: ContentfulClientApi;

export const createContentfulClient = (params: CreateClientParams): void => {
  /* istanbul ignore else */
  if (!client) {
    client = createClient(params);
  } 
};

export const getContentFromContentful = async <T>(entryId: string): Promise<Entry<T>> => {
    if (!client) {
    throw new Error('You need to create a contentful client using "createContentfulClient"');
  }

  if (!entryId) {
    return {} as Entry<T>;
  }

  const entries = await client.getEntries<T>({ 'sys.id': entryId, include: 3 });
  if (entries.items) {
    return entries.items[0];
  }

  return {} as Entry<T>;
};
export const getContentTypeFromContentful = async <T>(contentType : string): Promise<Entry<T>[]> => {
    if (!client) {
    throw new Error('You need to create a contentful client using "createContentfulClient"');
  }

  if (!contentType) {
    return {} as Entry<T>[];
  }

  const entries = await client.getEntries<T>({ 'content_type': contentType, select: 'fields'});
  if (entries.items) {
    return entries.items;
  }

  return {} as Entry<T>[];
};
export const getContentTypeLimaFromContentful = async <T>(contentType : string): Promise<Entry<T>[]> => {
    if (!client) {
    throw new Error('You need to create a contentful client using "createContentfulClient"');
  }

  if (!contentType) {
    return {} as Entry<T>[];
  }

  const entries = await client.getEntries<T>({ 'content_type': contentType, select: 'fields', 'fields.city': 'Lima' });
  if (entries.items) {
    return entries.items;
  }

  return {} as Entry<T>[];
};

export const getContentAdsFromContentful = async <T>(): Promise<Entry<T>[]> => {
    if (!client) {
    throw new Error('You need to create a contentful client using "createContentfulClient"');
  }

  const entries = await client.getEntries<T>({ 'content_type': 'Accomodation', select: 'fields', 'fields.bestwayPremium':'true' });
  if (entries.items) {
    return entries.items;
  }

  return {} as Entry<T>[];
};