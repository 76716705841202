import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { Entry } from "contentful";
import { StayDataType } from "data/types";
import { AccomodationInterface } from "domain/accomodation-item";
import { PackagesIntereface } from "domain/packages-item";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useGlobalAppDispatch, useGlobalAppState } from "store/app-context";
import { getContentTypeFromContentful } from "utils/contentful/contentful";
import SectionGridFilterCard from "./ListingStayPage/SectionGridFilterCard";

export interface PagePackagesProps {
  className?: string;
}

const PagePackages: FC<PagePackagesProps> = ({ className = "" }) => {
  const [packages, setPackages] = useState<Entry<PackagesIntereface>[]>([]);
  const dispatch = useGlobalAppDispatch();
  const { packagesList } = useGlobalAppState();

  useEffect(() => {
    getContentTypeFromContentful<any>("packages").then((content) => {
      setPackages(content);
    });
  }, []);
  return (
    <div
      className={`nc-PagePackages relative overflow-hidden ${className}`}
      data-nc-id="PagePackages"
    >
      <Helmet>
        <title>Bestway || Paquetes </title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        {/* <SectionHeroArchivePage
          currentPage="Stays"
          currentTab="Stays"
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        /> */}

        {/* SECTION */}
        <SectionGridFilterCard
          contentfulData={packages}
          className="pb-24 lg:pb-28"
        />

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style1"
            uniqueClassName="ListingStayMapPage"
          />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-28" /> */}

        {/* SECTION */}
        {/* <div className="relative py-16 mb-24 lg:mb-28">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}
      </div>
    </div>
  );
};

export default PagePackages;
